import React, { useState, useEffect, useRef } from 'react';
import './tarot_time.scss';
import { httpUrl } from '../../utils/apiService.js'
import axios from 'axios';
import { tarot_resver } from './tarot_card_base64.js'
import { getOtherConfig, getTarotTime } from '../chat/subview/locale.js';

const tarot_Img = tarot_resver;

const TarotFortune = ({ sendTarot, showTarot, locale }) => {
    const shuffleCardRefs = useRef([]); // 用于创建洗牌动画的ref数组
    const unfoldCardRefs = useRef([]);// 用于创建展牌动画的ref数组
    const cardsMeaningRef = useRef(null);
    const pageRef = useRef(null);
    const [clickedIndexes, setClickedIndexes] = useState([]); // 用于跟踪卡片的点击的索引

    //  cardImg 用于显示那张牌的图片
    const [selector1, setSelector1] = useState(null);
    const [selector2, setSelector2] = useState(null);
    const [selector3, setSelector3] = useState(null);

    const [cardImg1, setCardImg1] = useState([]);
    const [cardName1, setCardName1] = useState(null);
    const [cardFace1, setCardFace1] = useState(null);
    const [cardMeaning1, setCardMeaning1] = useState(null);

    const [cardImg2, setCardImg2] = useState([]);
    const [cardName2, setCardName2] = useState(null);
    const [cardFace2, setCardFace2] = useState(null);
    const [cardMeaning2, setCardMeaning2] = useState(null);

    const [cardImg3, setCardImg3] = useState([]);
    const [cardName3, setCardName3] = useState(null);
    const [cardFace3, setCardFace3] = useState(null);
    const [cardMeaning3, setCardMeaning3] = useState(null);

    //用于判断是否选择过
    const [isSelected, setIsSelected] = useState(false);

    //用于洗牌完成显示 展牌
    const [animationFinished, setAnimationFinished] = useState(false);

    const [current_locale, setCurrentLocale] = useState('');
    const totalItemBoxes = 78; // 假设有78个itemBox
    var delay = 0.025; // 洗牌单牌时间
    const delay_time = 0.028; // 展牌的单牌时间
    const translatexy = 0.2; // 一把牌的偏移量
    //点击跳转聊天
    const goChat = () => {
        sendTarot("tarot")
    }

    //点击卡牌事件处理
    const handleItemClick = (index) => {
        //数组内包含点击的索引，则不执行点击事件
        if (clickedIndexes.includes(index)) {
            return;
        }
        // 根据点击次数确定应该应用的CSS类
        let newClickedIndexes = [...clickedIndexes, index];
        setClickedIndexes(newClickedIndexes);
        if (newClickedIndexes.length == 1) {
            console.log("点击了第一张卡牌");
            // 将点击的卡片平移到指定位置
            const clickedCard = unfoldCardRefs.current[index];
            if (clickedCard) {
                clickedCard.style.animationDelay = '0s';
                clickedCard.style.animation = 'moveAndFlip_one 2s forwards';
                clickedCard.style.zIndex = '200';
            }
            setSelector1('one');
        }
        if (newClickedIndexes.length == 2) {
            console.log("点击了第二张卡牌");
            // 将点击的卡片平移到指定位置
            const clickedCard = unfoldCardRefs.current[index];
            if (clickedCard) {
                clickedCard.style.animationDelay = '0s';
                clickedCard.style.animation = 'moveAndFlip_two 2s forwards';
                clickedCard.style.zIndex = '200';
            }
            setSelector2('two');
        }
        if (newClickedIndexes.length == 3) {
            console.log("点击了第三张卡牌");
            // 将点击的卡片平移到指定位置
            const clickedCard = unfoldCardRefs.current[index];
            if (clickedCard) {
                clickedCard.style.animationDelay = '0s';
                clickedCard.style.animation = 'moveAndFlip_three 2s forwards';
                clickedCard.style.zIndex = '200';
            }
            setSelector3('three');
            setTimeout(() => {
                // 设置点击卡片为选中状态，并使用这个标识来隐藏所有卡牌，显示描述信息
                setIsSelected(true)
                // 延时两秒后显示 执行向上平移的动画（由于内容一屏显示不全）
                document.querySelector('.time_card_div').style.display = 'none';
                document.querySelector('.cards_list').style.display = 'flex';
                setTimeout(() => {
                    setSelector1('move-top1')
                    setSelector2('move-top2')
                    setSelector3('move-top3')
                    const parentElement = document.querySelectorAll('.unfold-cards-item');
                    const totalItems = parentElement.length;
                    for (let i = 0; i < totalItems; i++) {
                        parentElement[i].style.display = 'none';
                    }
                }, 2000)
            }, 1000)
        }
    };

    //获取信息
    const handleUserId = () => {
        setCurrentLocale(locale)
        getCardData(locale);
        setTimeout(() => {
            if (!animationFinished) {
                const parentElement = document.querySelectorAll('.shuffle-item');
                parentElement.forEach((element, index) => {
                    element.style.transform = `translateX(${index * - translatexy}px) translateY(${window.innerHeight / 2 + 120 + (index * -translatexy)}px)`;
                    if (index > 10) {
                        element.style.transitionDuration = `${(index) * delay}s`;
                    }
                });
                setTimeout(() => {
                    //开始洗牌动画 并给每张卡牌添加不同的动画名称 step-1—()
                    shuffleCardRefs.current.forEach((ref, index) => {
                        const animationName = `step-1-${index + 1}`;
                        generateKeyframes(animationName, index);
                        applyAnimationToElement(ref, animationName);
                    });
                }, 2200);
            }
        }, 1000);

        // 监听洗牌事件完成
        const timer = setTimeout(() => {
            setAnimationFinished(true);
        }, 6500);
        return () => clearTimeout(timer);

    }

    // 动态生成洗牌的关键帧动画
    const generateKeyframes = (animationName, index) => {
        let translateX, translate1X, translate2X, translate3X, translateY;
        if (index < 26) {
            translateX = -100;
            translate1X = 0;
            translate2X = -100;
            translate3X = 0;
            translateY = window.innerHeight / 2 + 120 + (index * -translatexy);
        } else if (26 <= index && index < 52) {
            translateX = 0;
            translate1X = -100;
            translate2X = 0;
            translate3X = -100;
            translateY = window.innerHeight / 2 + 120 + (index * -translatexy);
        } else if (52 <= index && index < 78) {
            translateX = 100;
            translate1X = 100;
            translate2X = 100;
            translate3X = 100;
            translateY = window.innerHeight / 2 + 120 + (index * -translatexy);
        }
        //translateX(${index * -translatexy}px) 
        const keyframes = `
            @keyframes ${animationName} {
                0% {
                    transform: translateX(${index * -translatexy}px) translateY(${window.innerHeight / 2 + 120 + (index * -translatexy)}px); 
                }
                15% {
                    transform: translateX(${translateX}px) translateY(${translateY}px); 
                }
    
                30% {
                    transform: translateX(${translate1X}px) translateY(${translateY}px);
                }
    
                45%,
                60% {
                    transform: translateX(${index * -translatexy}px) translateY(${window.innerHeight / 2 + 120 + (index * -translatexy)}px);
                }
                75% {
                    transform: translateX(${translate2X}px) translateY(${translateY}px);
                }
                90% {
                    transform: translateX(${translate3X}px) translateY(${translateY}px);

                }
                100% {
                    transform: translateX(${index * -translatexy}px) translateY(${window.innerHeight / 2 + 120 + (index * -translatexy)}px);
                }
            }
        `;
        const style = document.createElement('style');
        style.textContent = keyframes;
        document.head.appendChild(style);
    };

    // 将动态关键帧洗牌动画添加到元素
    const applyAnimationToElement = (element, animationName) => {
        element.style.animation = `${animationName} 2.5s ease-in-out `; // 调整动画持续时间等参数
    };

    // 请求卡牌数据
    const getCardData = (language_type) => {
        const tarot_data = JSON.parse(localStorage.getItem("tarot_one"))
        if (tarot_data) {
            setCardImg1(tarot_data.taluodata[0].new_img)
            setCardName1(tarot_data.taluodata[0].name)
            setCardFace1(tarot_data.taluodata[0].face_text)
            setCardMeaning1(tarot_data.taluodata[0].wen_desc)

            setCardImg2(tarot_data.taluodata[1].new_img)
            setCardName2(tarot_data.taluodata[1].name)
            setCardFace2(tarot_data.taluodata[1].face_text)
            setCardMeaning2(tarot_data.taluodata[1].wen_desc)

            setCardImg3(tarot_data.taluodata[2].new_img)
            setCardName3(tarot_data.taluodata[2].name)
            setCardFace3(tarot_data.taluodata[2].face_text)
            setCardMeaning3(tarot_data.taluodata[2].wen_desc)
        }
    }
    // 处理请求的卡牌数据 重新整合
    const processData = (rawData) => {
        // 将 data 替换为 taluodata，并删除每个对象中的 image 属性
        const processedData = rawData.map(({ image, ...rest }) => rest);
        return { taluodata: processedData };
    };


    // 添加 showMoreButton 的点击事件监听器
    const handleClick1 = () => {
        const cardsMeaning = document.querySelector('.cards_meaning1');
        const showMoreButton = document.querySelector('.show_more1')
        cardsMeaning.style.webkitLineClamp = 'initial'; // 移除行数限制
        cardsMeaning.style.display = 'block';
        showMoreButton.style.display = 'none'; // 隐藏"显示更多"按钮
    };
    const handleClick2 = () => {
        const cardsMeaning = document.querySelector('.cards_meaning2');
        const showMoreButton = document.querySelector('.show_more2')
        cardsMeaning.style.webkitLineClamp = 'initial'; // 移除行数限制
        cardsMeaning.style.display = 'block';
        showMoreButton.style.display = 'none'; // 隐藏"显示更多"按钮
    };
    const handleClick3 = () => {
        const cardsMeaning = document.querySelector('.cards_meaning3');
        const showMoreButton = document.querySelector('.show_more3')
        cardsMeaning.style.webkitLineClamp = 'initial'; // 移除行数限制
        cardsMeaning.style.display = 'block';
        showMoreButton.style.display = 'none'; // 隐藏"显示更多"按钮
    };

    useEffect(() => {
        if (showTarot) {
            handleUserId();
        }
        //handleUserId();
    }, []);

    useEffect(() => {
        // 当 animationFinished 状态变为 true 时执行展牌操作
        if (animationFinished) {
            const parentElement = document.querySelectorAll('.unfold-cards-item');
            const totalItems = parentElement.length;
            const distanceBetweenItems = 10; // 调整每个元素之间的距离
            for (let i = 0; i < totalItems; i++) {
                if (i < 20) {
                    parentElement[i].style.transform = `rotate(${-90}deg) translate(${200 - (i * distanceBetweenItems)}px, ${-120}px) scale(0.65)`;
                    parentElement[i].style.animationDelay = `${i * delay_time}s`;
                } else if (20 <= i && i <= 55) {
                    parentElement[i].style.transform = `rotate(${-90 - ((i - 20) * 5)}deg) translate(${0}px, ${-120}px) scale(0.65)`;
                    parentElement[i].style.animationDelay = `${i * delay_time}s`;
                } else if (i >= 56) {
                    parentElement[i].style.transform = `rotate(${90}deg) translate(-${(i - 56) * distanceBetweenItems}px, ${-120}px) scale(0.65)`;
                    parentElement[i].style.animationDelay = `${i * delay_time}s`;
                }
            }

        }
    }, [animationFinished]);

    return (
        <div id="page-container" ref={pageRef}>
            {!animationFinished && (
                <div className="shuffle-container">
                    <div className="shuffle-subcontainer">
                        {Array.from({ length: totalItemBoxes }, (_, index) => (
                            <div
                                key={index}
                                ref={(element) => (shuffleCardRefs.current[index] = element)}
                                className={`shuffle-item`}
                            >
                                <div className="shuffle-item-img">
                                    <img src={tarot_Img} className="shuffle-item-imgbg" alt="Tarot Card Bg" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {animationFinished && (
                <div className={`unfold-cards-subcontainer`}>
                    {Array.from({ length: totalItemBoxes }).map((_, index) => (
                        <div
                            key={index}
                            className={`unfold-cards-item ${isSelected ? 'unfold-hide-Animation' : 'unfold-default-Animation'}`}
                            style={{ background: `url(${tarot_resver}) no-repeat 50%/100% 100%` }}
                            onClick={() => handleItemClick(index)}
                            ref={(element) => (unfoldCardRefs.current[index] = element)}
                        ></div>
                    ))}
                    <div className='time_card_div'>
                        <div className='time_card'>
                            {getTarotTime(current_locale)[0]}
                        </div>
                        <div className='time_card'>
                            {getTarotTime(current_locale)[1]}
                        </div>
                        <div className='time_card'>
                            {getTarotTime(current_locale)[2]}
                        </div>
                    </div>
                    <img
                        className={`open-cards-img open-card-animation-${selector1}`}
                        src={`data:image/jpeg;base64,${cardImg1}`}
                    />
                    <img
                        className={`open-cards-img open-card-animation-${selector2}`}
                        src={`data:image/jpeg;base64,${cardImg2}`}
                    />
                    <img
                        className={`open-cards-img open-card-animation-${selector3}`}
                        src={`data:image/jpeg;base64,${cardImg3}`}
                    />
                    <div className={`cards_list ${!isSelected ? 'unfold-hide-Animation' : 'unfold-show-Animation'}`}>
                        <div className='cards_title'>
                            <span className='cards_title_name'>
                                {cardName1}
                                <p style={{ background: 'linear-gradient(0deg, #082834, #0b3342)',boxShadow:'0 4px 8px rgba(0, 0, 0, 0.2)', borderRadius: '8px', padding: '5px' }}>{cardFace1}</p>
                            </span>
                            <span className='cards_title_name'>
                                {cardName2}
                                <p style={{ background: 'linear-gradient(0deg, #082834, #0b3342)',boxShadow:'0 4px 8px rgba(0, 0, 0, 0.2)', borderRadius: '8px', padding: '5px' }}>{cardFace2}</p>
                            </span>
                            <span className='cards_title_name'>
                                {cardName3}
                                <p style={{ background: 'linear-gradient(0deg, #082834, #0b3342)',boxShadow:'0 4px 8px rgba(0, 0, 0, 0.2)', borderRadius: '8px', padding: '5px' }}>{cardFace3}</p>
                            </span>
                        </div>
                        <div className={`cards_content`}>
                            <div className="cards_content_name_face" >
                                <p className='cards_content_name'>{cardName1}</p>
                                <p className='cards_content_face'>{getTarotTime(current_locale)[0]}</p>
                            </div>
                            <div className='cards_meaning1' ref={cardsMeaningRef}>
                                <div className='cards_image' alt="meaning" >
                                    <img src={`data:image/jpeg;base64,${cardImg1}`} alt="meaning" className='cards_image_img' />
                                    <div className='cards_image_name'>{cardFace1}</div>
                                </div>
                                {cardMeaning1}
                            </div>
                            <div className='show_more1' onClick={handleClick1}>
                                {getOtherConfig(current_locale)[2]}
                            </div>
                        </div>
                        <div className={`cards_content`}>
                            <div className="cards_content_name_face" >
                                <p className='cards_content_name'>{cardName2}</p>
                                <p className='cards_content_face'>{getTarotTime(current_locale)[1]}</p>
                            </div>
                            <div className='cards_meaning2' ref={cardsMeaningRef}>
                                <div className='cards_image' alt="meaning" >
                                    <img src={`data:image/jpeg;base64,${cardImg2}`} alt="meaning" className='cards_image_img' />
                                    <div className='cards_image_name'>{cardFace2}</div>
                                </div>
                                {cardMeaning2}
                            </div>
                            <div className='show_more2' onClick={handleClick2}>
                                {getOtherConfig(current_locale)[2]}
                            </div>
                        </div>
                        <div className={`cards_content`}>
                            <div className="cards_content_name_face" >
                                <p className='cards_content_name'>{cardName3}</p>
                                <p className='cards_content_face'>{getTarotTime(current_locale)[2]}</p>
                            </div>
                            <div className='cards_meaning3' ref={cardsMeaningRef}>
                                <div className='cards_image' alt="meaning" >
                                    <img src={`data:image/jpeg;base64,${cardImg3}`} alt="meaning" className='cards_image_img' />
                                    <div className='cards_image_name'>{cardFace3}</div>
                                </div>
                                {cardMeaning3}
                            </div>
                            <div className='show_more3' onClick={handleClick3}>
                                {getOtherConfig(current_locale)[2]}
                            </div>
                        </div>
                    </div>
                    <div className={`go_chat ${!isSelected ? 'unfold-hide-Animation' : 'unfold-show-Animation'}`}
                        onClick={() => goChat()}>
                        {getOtherConfig(current_locale)[3]}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TarotFortune;
