import React from 'react';
import { useNavigate } from 'react-router-dom';

const PageTitle = ({ title }) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    document.title = title || '默认标题';
  }, [title]);

  // 在组件卸载时，不需要执行任何清理操作，因为没有监听路由变化

  return null;
};

export default PageTitle;
