import React from "react";
import { FilledIcon, EmptyIcon, HalfIcon } from './RateIcon.js';
const Rating = ({ rating, width }) => {

    // 限制 rating 的最大值为 5
    const cappedRating = Math.min(rating, 5);
    const fullIconsCount = Math.floor(cappedRating);
    const hasHalfIcon = cappedRating % 1 !== 0;
    const icons = [];

    for (let i = 0; i < fullIconsCount; i++) {
        icons.push(<img key={i} src={FilledIcon} style={{ width: `${width}px`, height: `${width}px` }} />);
    }

    if (hasHalfIcon) {
        icons.push(<img key="half" src={HalfIcon} style={{ width: `${width/2}px`, height: `${width}px` }} />);
    }

    return (
        <div className='rating_div' style={{ position: 'relative', height: `${width}px` }}>
            <div style={{ width: '100%', height: '100%' }}>
                {Array(5).fill().map((_, i) => (
                    <img key={`empty-${i}`} src={EmptyIcon} style={{ width: `${width}px`, height: `${width}px` }} />
                ))}
            </div>
            <div style={{ position: 'absolute', top: 0, left: 0, display: 'flex' }}>
                {icons}
            </div>
        </div>
    );
};

export default Rating;