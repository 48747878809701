import React, { Component } from "react";
import "../index.scss";
import UserMessage from "../subview/UserMessage";
import AiMessage from "../subview/AiMessage";
import GoodsMessage from "../subview/GoodsMessage";
import WebSocketClient from "../../../utils/websocket";
import { DatetimePicker, Skeleton, Picker, Radio, Toast, Popup, Button, Popover } from 'react-vant';
import { Menu, Dropdown, DatePicker } from 'antd';
import '@vant/touch-emulator';
import LoadingMessage from "../subview/loadingMessage";
import StarDiskMessage from "../subview/StarDiskMessage";
import 'animate.css';
import PageTitle from "../../../utils/pageTitle";
import ageUtils from '../../../utils/ageUtils';
import { wsUrl, httpUrl } from '../../../utils/apiService';
import ReactGA from "react-ga4";
import PcGoodsMessage from "../subview/PcGoodsMessage";
import ColorChangeButton from "../subview/ColorChangeButton";
import axios from 'axios';
import HeadTitle from "../subview/HeadTitle";
import { getLocaleDescription, getPickerPhrases, getUserInfoLanguage, getSendUserInfoLanguage, getOtherConfig, getGenderLanguage, getSendEmail } from "../subview/locale.js";
import dayjs from "dayjs";
import AudioRecorder from "../subview/AudioRecorder.js";
import HelloMessage from "../subview/HelloMessage.js";
import DissMessage from "../subview/dissMessage.js";
import Asr from "../subview/asr/Asr.js";
//const defaultImg = require("../../../assets/img/bazi_avatar2.png");
const defaultImg = require("../../../assets/img/chengliangming_head.png");
const sendBtnIng = require("../../../assets/img/send_ing.png");
const sendBtnYes = require("../../../assets/img/send_yes.png");
const sendBtnNo = require("../../../assets/img/send_no.png");
const voice = require("../../../assets/img/voice.png");
const floatingBtn = require("../../../assets/img/scroll_down.png");
const deleteTag = require("../../../assets/img/del_tag.png");
const editInfo = require("../../../assets/img/edit.png");
const zhanxingbackground = require("../../../assets/img/zhanxing_background.png");

const baziPerson = require("../../../assets/img/chengliangming.png");

//消息文本内容
let newMessage = "";
//是否第一个end=false的文本
let count = 0;
let current_locale = '';
//当前语言
let language_type = [];
//picker 语言
let picker_language = [];
//性别数据 语言
let gender_columns = [];
//性别 血型 星座 生日 
let selectedOptions = {
    gender: '',
    bloodType: '',
    constellation: '',
    birthday: '',
};
//是否可以触发发送按钮
let canSend = true;

//浏览器中是否有消息内容的缓存  true会接受招呼 false不会接受招呼
let first_message = true;

// 记录初始触摸点位置
let startY = 0;

// 设置一个超时，等待消息
const timeoutDuration = 1500;
//记录点击 用于过滤底部拦
let clickBottom = [];

//用于判断是从聊天内选择信息 还是 修改信息
let isChoose = false;
//用于判断当前点击等待的是那个条目的八字盘信息
let baziDiskIndex = 0;

let messageQueue = []; // 消息队列
let isProcessing = false; // 是否正在处理消息
const delay = 100; // 延迟处理时间，单位毫秒
let intervalId = null; // 定时器 ID

let audioQueue = []; // 音频消息队列
let isProcessingAudio = false; // 是否正在处理音频消息
let source = null; //音频源

let email_hash = '';//发送邮箱需要消息md5对应

const audioContext = new (window.AudioContext || window.webkitAudioContext)();

//录制出来的语音文本
let recordText = '';
//音频播放是否停止
let isStopAudio = false;

//音频播放队列是否处理完成标记
let isQueueEmptyAfterProcess = false;

let debounceStopAudio = true;

export default class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //消息列表
            messageList: [],
            //ws参数相关
            ws: null,
            sessionId: '',
            userId: '',
            userName: '',
            userAge: '',
            userGender: '',
            birthday: '',
            // userAvatar: 'africa',
            // birthplace: 'Los Angeles',
            // botName: 'ChineseFortuneTeller',
            // skuId: ',,Love',
            // motive: 'Love',

            botName: 'ChineseFortuneTeller',
            userAvatar: '',
            birthplace: '',
            skuId: ',,',
            motive: '',

            preferenceTags: '',

            //底部栏内容 性别、血型、星座等
            bottomItems: [],
            bottomItemsShow: false,
            //选中的内容在输入框显示
            choosetag: [],
            //生日默认选中
            birthday_values: new Date(1999, 0, 1),
            //是否显示闪烁光标
            showCursor: false,
            showSend: true,
            //是否显示触底按钮
            isBottomVisible: false,
            //是否显示聊天loading
            isChatLoading: true,

            //pc端 商品
            product: [],
            FortuneTellerName: '',
            //是否显示用户 选择yes no
            isShowYesNo: false,

            DropdownVisible: false,
            menu: "",
            datePcVisible: false,

            //是否显示邮箱发送的弹窗
            sendEmailVisible: false,
            //邮箱
            email: '',
            //邮箱是否可以通过交验
            isValidEmail: true,
            //是否显示语音识别底部栏
            isShowVoice: false,
            //是否正在录音
            isRecording: false,
            //音量
            volume: 0,
            //静默时间导致停止通知语音控件复原
            vadEnd: false,
            //用于判断当前播放的是那个条目的语音
            playAudioIndex: -1,
            //用于判断是否为加载出来语音播放
            showAudioLoading: false,
            //用于判断是否显示招呼语内容,
            isHelloView: false,
            inputValue: '',
            tipsList: [],
            //是否显示商品详情
            isShowProduct: false,
            iframeSrc: '',

            //是否显示ASR页面
            isShowASR: false,
            vadDelay: 0,
        };
        this.reconnectAttempts = 0,//当前重连次数
            this.MAX_RECONNECT_ATTEMPTS = 5;//最大重连次数
        this.RECONNECT_INTERVAL = 5000;//重连时间

        //底部联想词
        this.popoverRef = React.createRef();
        //发送消息
        this.sendMessage = this.sendMessage.bind(this);
        //消息列表滚动
        this.messagesContainerRef = React.createRef();
        //输入框
        this.inputFieldRef = React.createRef();
        //性别选择器
        this.pickerGenderRef = React.createRef();
        //时间选择器
        this.datePickerRef = React.createRef();
        //流式内容输出次数，用于判断减少多次触发触底操作
        this.updateNum = 0;

        this.defaultValue = dayjs('1999-01-01');
    }
    //pc商品数据请求展示
    pcHttpSku = (content) => {
        if (content && content[0]) {
            axios({
                method: 'get',
                url: httpUrl + '/api/dtc_sku_list',
                params: {
                    "inventory_quantity": content[0].inventory_quantity,
                    "main_material": content[0].main_material,
                    "product_status": content[0].product_status,
                    "ethnicity": content[0].ethnicity,
                    "target_audience": content[0].target_audience,
                    "change_purpose": content[0].change_purpose,
                    "in_house_development": content[0].in_house_development,
                    "customizable": content[0].customizable,
                    "customizable_accessories": content[0].customizable_accessories,
                    "product_category": content[0].product_category,
                    "esoteric_tag": content[0].esoteric_tag,
                }
            })
                .then(response => {
                    this.setState({
                        product: response.data.data
                    })
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
    handleUserId = (event) => {
        // 提取并设置 userId
        const sessionId = localStorage.getItem("sessionId") || '';
        const loaclMessageList = JSON.parse(localStorage.getItem("bazimessageList")) || [];
        if (loaclMessageList.length > 0) {
            first_message = false;
        } else {
            first_message = true;
        }
        if (event && event.data.userId) {
            const user_id = event.data.userId == '0' ? "" : event.data.userId
            const user_avatar = event.data.userAvatar == 'default' ? 'africa' : event.data.userAvatar
            const birthplace = event.data.birthplace == 'Unknown' ? 'Los Angeles' : event.data.birthplace
            const motive = event.data.motive == 'None' ? 'Love' : event.data.motive
            let sku_id = ',,' + motive
            if (event.data.skuId == 'None' || event.data.skuId.split(',').length !== 3) {
                sku_id = ',,' + motive
            } else {
                sku_id = event.data.skuId
            }
            const locale = event.data.locale;
            current_locale = locale;
            language_type = getLocaleDescription(locale)
            picker_language = getPickerPhrases(locale)
            gender_columns = getGenderLanguage(locale)
            this.setState({
                userId: user_id,
                userAvatar: user_avatar,
                birthplace: birthplace,
                motive: motive,
                skuId: sku_id,
                sessionId: sessionId
            }, () => {
                if (this.state.ws) {
                    this.state.ws.close();
                    this.setState({ ws: null }, () => {
                        this.connectWebSocket();
                    });
                } else {
                    this.connectWebSocket();
                }
            });
        } else {
            current_locale = 'en'
            language_type = getLocaleDescription('en')
            picker_language = getPickerPhrases('en')
            gender_columns = getGenderLanguage('en')
            this.setState({
                sessionId: sessionId
            }, () => {
                if (this.state.ws) {
                    this.state.ws.close();
                    this.setState({ ws: null }, () => {
                        this.connectWebSocket();
                    });
                } else {
                    this.connectWebSocket();
                }
            });
        }
    }
    //组件挂载 连接ws
    componentDidMount() {
        //TODO 用于测试
        localStorage.removeItem("sessionId")
        localStorage.removeItem("bazimessageList")
        localStorage.removeItem("bottomList")

        if (window.self !== window.top) {
            // 在iframe中 等待父窗口发送消息
            window.addEventListener('message', (event) => {
                if (event) {
                    if (event.data.canLoad == true) {
                        setTimeout(() => {
                            this.handleUserId(event);
                        }, timeoutDuration);
                    }
                }
            });
        } else {
            //不在iframe中 直接加载
            setTimeout(() => {
                this.handleUserId();
            }, timeoutDuration);
        }
        window.addEventListener('pagehide', this.handleBeforeUnload);
        // 为输入框添加焦点和失去焦点事件的监听
        const inputField = this.inputFieldRef.current;
        if (inputField) {
            inputField.addEventListener('focus', this.handleFocus);
            inputField.addEventListener('blur', this.handleBlur);
        }
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        window.addEventListener('resize', this.handleResize);
        // 添加滚动事件监听器
        const container = this.messagesContainerRef.current;
        if (container) {
            container.addEventListener('scroll', this.handleScroll);
            // 添加 touchstart、touchmove、touchend 事件监听器
            container.addEventListener('touchstart', this.handleTouchStart);
            container.addEventListener('touchmove', this.handleTouchMove);
            container.addEventListener('touchend', this.handleTouchEnd);
        }
    }
    //触摸开始
    handleTouchStart = (event) => {
        event.stopPropagation();
        // 获取触摸点的初始位置
        startY = event.touches[0].clientY;
    };
    // 触摸移动事件处理函数
    handleTouchMove = (event) => {
        event.stopPropagation();
        const chatContent = this.messagesContainerRef.current
        // 获取当前触摸点的位置
        const currentY = event.touches[0].clientY;
        // 计算下拉的距离
        const pullDistance = currentY - startY;
        // 如果下拉距离大于 0，并且容器在顶部
        if (pullDistance > 0 && chatContent.scrollTop <= 0) {
            // 阻止浏览器的下拉刷新行为
            if (event.cancelable) {
                event.preventDefault();
            }
            const dampingDistance = pullDistance / 5; // 根据滑动距离计算阻尼距离
            chatContent.style.transition = 'transform 0.3s ease-out';
            chatContent.style.transform = `translate3d(0px, ${dampingDistance}px, 0px)`;
        }
        // 如果上拉距离小于 0，并且容器在底部
        if (pullDistance < 0 && chatContent.scrollHeight - chatContent.scrollTop <= chatContent.clientHeight + 5) {
            // 阻止浏览器的默认行为
            if (event.cancelable) {
                event.preventDefault();
            }
            const dampingDistance = pullDistance / 5; // 根据滑动距离计算阻尼距离
            chatContent.style.transition = 'transform 0.3s ease-out';
            chatContent.style.transform = `translate3d(0px, ${dampingDistance}px, 0px)`;
        }
    };
    // 触摸结束事件处理函数
    handleTouchEnd = (event) => {
        const chatContent = this.messagesContainerRef.current;
        if (chatContent) {
            chatContent.style.transition = 'transform 0.3s ease-out'; // 过渡效果
            chatContent.style.transform = 'translate3d(0px, 0px, 0px)'; // 恢复原始位置
        }
    };
    // 定义一个函数来调整聊天内容容器的高度
    adjustChatContent = () => {
        if (this.messagesContainerRef.current && this.inputFieldRef.current) {
            // 滚动聊天内容到底部
            this.scrollToBottom()
        }
    };
    // 当输入框获得焦点时
    handleFocus = () => {
        this.adjustChatContent();
    };
    // 当输入框失去焦点时
    handleBlur = () => {
        //this.adjustChatContent();
    };
    // 监听窗口大小变化事件
    handleResize = () => {
        setTimeout(() => {
            this.adjustChatContent();
        }, 1000)
    };
    //页面显示监听
    handleVisibilityChange = () => {
        if (document.visibilityState == 'visible') {
            setTimeout(() => {
                this.adjustChatContent();
            }, 500)
        }
    }
    //滑动监听
    handleScroll = () => {
        this.setState({
            isBottomVisible: !this.isLastElementVisible()
        });
    }
    //组件卸载 关闭ws
    componentWillUnmount() {
        window.removeEventListener('pagehide', this.handleBeforeUnload);

        const chatContent = this.messagesContainerRef.current;
        if (chatContent) {
            chatContent.removeEventListener('scroll', this.handleScroll);
            chatContent.removeEventListener('touchstart', this.handleTouchStart);
            chatContent.removeEventListener('touchmove', this.handleTouchMove);
            chatContent.removeEventListener('touchend', this.handleTouchEnd);
        }
        const inputField = this.inputFieldRef.current;
        if (inputField) {
            inputField.removeEventListener('focus', this.handleFocus);
            inputField.removeEventListener('blur', this.handleBlur);
        }
        window.removeEventListener('resize', this.handleResize);
        // 在组件卸载时移除消息监听器
        window.removeEventListener('message', this.handleUserId);
    }
    handleBeforeUnload = (event) => {
        // 在页面即将被卸载时执行清理操作
        console.log("页面即将被卸载");
        if (this.state.ws) {
            this.state.ws.close();
            this.setState({ ws: null });
        }
    }
    //ws
    connectWebSocket = () => {
        const { sessionId, userId, userName, userAge, userGender, birthday, userAvatar, birthplace, botName, skuId, preferenceTags, motive } = this.state;
        const queryParams = new URLSearchParams({
            session_id: sessionId,
            user_id: userId,
            user_name: userName,
            user_age: userAge,
            user_gender: userGender,
            birthday: birthday,
            user_avatar: userAvatar,
            birthplace: birthplace,
            bot_name: botName,
            sku_id: skuId,
            motive: motive,
            preference_tags: preferenceTags,
            first_message: first_message,
            language_type: language_type,
        });
        const websocket = new WebSocketClient(`${wsUrl}?${queryParams.toString()}`);
        this.setState({ ws: websocket });

        websocket.onOpen = () => {
            console.log('WebSocket connected.');
            this.reconnectAttempts = 0;
            this.pushGa4('wsConnect', 'connect', 'bazi')
        };

        websocket.onMessage = (data) => {
            // 处理接收到的消息
            // 普通message消息文本 code=1001
            // 星座开场招呼语询问性别血型 code=1002
            // 询问选择星座 code=2001
            // 返回星座信息 code=2002
            // 八字询问性别 code=3001
            // 八字询问生日 code=3002
            // type=“string” 表示以上内容
            // type=“sku” 表示商品
            // type=“loading” 表示加载中（前端展示）
            try {
                if (data instanceof Blob) {
                    // Received Blob data
                    const fileReader = new FileReader();
                    fileReader.onload = (e) => {
                        const arrayBuffer = e.target.result;
                        audioQueue.push(arrayBuffer)
                        // 如果没有正在处理消息，则开始处理队列
                        if (!isProcessingAudio) {
                            this.processAudioQueue();
                        }
                    };
                    fileReader.readAsArrayBuffer(data);
                } else if (data instanceof ArrayBuffer) {
                    // Received ArrayBuffer data
                    this.setState({
                        showAudioLoading: false
                    })
                    audioQueue.push(data)
                    // 如果没有正在处理消息，则开始处理队列
                    if (!isProcessingAudio) {
                        this.processAudioQueue();
                    }
                } else {
                    const messageData = JSON.parse(data);
                    messageQueue.push(messageData);
                    // 如果没有正在处理消息，则开始处理队列
                    if (!isProcessing) {
                        this.processMessageQueue();
                    }
                }
            } catch (error) {

            }
        };

        websocket.onClose = () => {
            console.error('WebSocket disconnected.');
        };

        websocket.onError = (error) => {
            console.error('WebSocket error:', error);
            if (this.state.ws) {
                if (this.reconnectAttempts < this.MAX_RECONNECT_ATTEMPTS) {
                    console.log(`Reconnecting in ${this.RECONNECT_INTERVAL} milliseconds...`);
                    setTimeout(() => {
                        this.reconnectAttempts = this.reconnectAttempts + 1;
                        this.connectWebSocket();
                    }, this.RECONNECT_INTERVAL);
                } else {
                    console.log('Max reconnect attempts reached. Stop reconnecting.');
                }
            }
            //this.showErrorMessage();
        };

        websocket.connect();

    }
    playAudioBuffer = (buffer) => {
        if (source) {
            source.stop(); // 停止之前的音频
            source = null;
        }
        source = audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContext.destination);
        source.onended = () => {
            if (audioQueue.length > 0) {
                this.processAudioQueue();
            } else {
                isProcessingAudio = false;
                isQueueEmptyAfterProcess = true; // 标记队列处理完成且为空
            }
            if (isQueueEmptyAfterProcess) {
                if (isStopAudio && audioQueue.length == 0) {
                    this.stopAudio(false);
                    //如果当前显示着ASR 则播放结束自动开启录音
                    if (this.state.isShowASR) {
                        this.startAsrRecord();
                    }
                }
            }
        }
        source.start(0);
    }

    createAudioBufferFromPCM(arrayBuffer, sampleRate, numberOfChannels, bitsPerSample) {
        const numberOfFrames = arrayBuffer.byteLength / (bitsPerSample / 8 * numberOfChannels);
        const audioBuffer = audioContext.createBuffer(numberOfChannels, numberOfFrames, sampleRate);

        const view = new Int16Array(arrayBuffer);
        for (let channel = 0; channel < numberOfChannels; channel++) {
            const nowBuffering = audioBuffer.getChannelData(channel);
            for (let frame = 0; frame < numberOfFrames; frame++) {
                nowBuffering[frame] = view[frame * numberOfChannels + channel] / 32768;
            }
        }
        return audioBuffer;
    }
    //展示一条错误信息
    showErrorMessage = () => {
        //将一些用于判断的字段 置为初始状态
        count = 0
        newMessage = ''
        canSend = true
        const errorMessage = {
            content: 'Sorry for encountering an error temporarily!',
            isUser: false,
            type: 'string',
            avator: defaultImg,
            botName: this.state.botName,
        };
        this.setState(prevState => {
            const { messageList } = prevState;
            const lastIndex = messageList.length - 1;
            const lastMessage = messageList[lastIndex];
            const shouldRemoveLastMessage = lastMessage && lastMessage.type == "loading";
            const newMessageList = shouldRemoveLastMessage ? messageList.slice(0, lastIndex) : [...messageList];

            return {
                showCursor: false,
                showSend: true,
                messageList: [...newMessageList, errorMessage],
            }
        }, () => {
            this.scrollToBottom();
            this.uploadCache();
        });
    }
    // 处理消息队列函数
    processMessageQueue = () => {
        if (messageQueue.length === 0) {
            isProcessing = false;
            return;
        }
        isProcessing = true;
        const message = messageQueue.shift(); // 从队列中取出第一个消息
        try {
            this.handleMessage(message);
        } catch (error) {
            console.error('Error handling message:', error);
        }
        // 继续处理下一个消息，或者等待下一个定时器触发
        intervalId = setTimeout(this.processMessageQueue, delay);
    }
    // 处理音频流队列函数
    processAudioQueue = () => {
        if (audioQueue.length === 0) {
            isProcessingAudio = false;
            isQueueEmptyAfterProcess = true; // 标记队列已处理完且为空
            return;
        }
        isProcessingAudio = true;
        isQueueEmptyAfterProcess = false; // 处理过程中设置为 false
        const audioBuffer = audioQueue.shift(); // 从队列中取出第一个消息
        try {
            const audio = this.createAudioBufferFromPCM(audioBuffer, 44100, 1, 16);
            this.playAudioBuffer(audio);
        } catch (error) {
            console.error('Error handling message:', error);
        }
    }

    // 在必要时停止处理消息队列，清除定时器等
    stopProcessing = () => {
        clearTimeout(intervalId);
        isProcessing = false;
        messageQueue = [];
    }
    //处理接收到的消息
    handleMessage = (messageData) => {
        // 解构赋值从 messageData 对象中提取所需的属性
        const { code, end, type, content, bot_name, session_id } = messageData;
        // 更新消息列表的函数
        const handleMessageListUpdate = (newMessageItem, isEnd) => {
            this.setState(prevState => {
                const { messageList } = prevState;
                const lastIndex = messageList.length - 1;
                const lastMessage = messageList[lastIndex];
                const shouldRemoveLastMessage = lastMessage && lastMessage.type == "loading";
                const newMessageList = shouldRemoveLastMessage ? messageList.slice(0, lastIndex) : [...messageList];

                if (newMessageItem.category != '') {
                    if (newMessageItem.category == 9000) {
                        return {
                            showCursor: !isEnd,
                            showSend: isEnd && canSend,
                            messageList: newMessageList.map((item, index) =>
                                index === newMessageList.length - 1 ?
                                    {
                                        ...item,
                                        category: newMessageItem.category,
                                        moreContent: '',
                                        cesuan_hide_key: messageData.msg,
                                        isShowDetail: false
                                    } :
                                    item
                            )
                        };
                    }
                    // 当 category 是特殊类型且 content 为空时，更新最后一条消息
                    const isSpecialCategory = [3001, 3002].includes(newMessageItem.category);
                    if (isSpecialCategory && newMessageItem.content == '') {
                        return {
                            showCursor: !isEnd,
                            showSend: isEnd && canSend,
                            messageList: newMessageList.map((item, index) =>
                                index === newMessageList.length - 1 ?
                                    { ...item, category: newMessageItem.category } :
                                    item
                            )
                        };
                    } else if (newMessageItem.content != '') {
                        // 当 content 不为空时，新增条目
                        return {
                            showCursor: !isEnd,
                            showSend: isEnd && canSend,
                            messageList: [
                                ...newMessageList,
                                newMessageItem,
                            ],
                        };
                    }
                }
                return {
                    showCursor: !isEnd,
                    showSend: isEnd && canSend,
                    botName: bot_name,
                    sessionId: session_id,
                };
            }, () => {
                if (!this.state.isBottomVisible) {
                    this.scrollToBottom();
                    if (newMessageItem.category == 3001 || newMessageItem.category == 3002 || newMessageItem.category == 9030 || newMessageItem.category == 1001) {
                        setTimeout(() => {
                            this.scrollToBottom();
                        }, 1000);
                    }
                }
                this.uploadCache();
            });
        };
        // type=sessionId的处理
        const handleTypeSessionId = () => {
            if (this.state.sessionId == session_id) {
                const loaclMessageList = JSON.parse(localStorage.getItem("bazimessageList")) || [];
                const localBottomList = JSON.parse(localStorage.getItem("bottomList")) || {};
                let baziBottomList = [];
                if (localBottomList) {
                    if (localBottomList.gender) {
                        baziBottomList.push(localBottomList.gender)
                        selectedOptions["gender"] = localBottomList.gender
                    }
                    if (localBottomList.birthday) {
                        baziBottomList.push(localBottomList.birthday)
                        selectedOptions["birthday"] = localBottomList.birthday
                    }
                }
                this.setState({
                    sessionId: session_id,
                    messageList: loaclMessageList,
                    bottomItems: baziBottomList,
                    bottomItemsShow: baziBottomList.length > 0,
                }, () => {
                    this.setState({ isChatLoading: false })
                    setTimeout(() => {
                        this.scrollToBottom();
                    }, 1000)
                });
            } else {
                ReactGA.set({
                    user_id: "" + session_id
                })
                ReactGA.gtag('set', 'user_properties', {
                    custom_session_id: session_id
                });
                this.setState({
                    sessionId: session_id,
                    isChatLoading: false,
                })
                localStorage.removeItem("sessionId")
                localStorage.removeItem("bazimessageList")
                localStorage.removeItem("bottomList")
            }
        }
        // type=FortuneTellerName
        const handleTypeFortuneTellerName = () => {
            this.setState({ FortuneTellerName: content });
        }
        // type=string
        const handleTypeString = () => {
            if (code == 1001 || code == 3001 || code == 3002 || code == 9000) {
                if (end == false) {
                    // 如果消息未结束
                    if (newMessage) {
                        newMessage += content; // 将消息内容添加到新消息中
                    } else {
                        newMessage = content; // 否则设置新消息内容
                    }
                    if (count == 0) {
                        count++;
                        canSend = false; // 设置不可发送
                        this.updateNum = 0; // 重置更新计数器
                        // 更新消息列表
                        handleMessageListUpdate({
                            type: "string",
                            isUser: false,
                            avator: defaultImg,
                            botName: bot_name,
                            category: code,
                            content: newMessage,
                        }, false);
                    } else {
                        // 更新消息列表中最后一条消息的内容
                        this.setState((prevState) => ({
                            messageList: prevState.messageList.map((message, index) => {
                                if (index === prevState.messageList.length - 1) {
                                    return {
                                        ...message,
                                        content: newMessage,
                                    };
                                }
                                return message;
                            })
                        }), () => {
                            // 更新计数器并根据情况滚动到底部或刷新滚动区域
                            this.updateNum++;
                            if (!this.state.isBottomVisible && this.updateNum % 20 === 0) {
                                this.scrollToBottom();
                            }
                        });
                    }
                } else if (end == true) {
                    handleMessageListUpdate({
                        type: "string",
                        isUser: false,
                        avator: defaultImg,
                        botName: bot_name,
                        category: code,
                        content: content,
                    }, true);
                    //设置可以发送消息
                    canSend = true;
                    // 重置新消息和计数器
                    newMessage = "";
                    count = 0;
                }
            }
            switch (code) {
                //普通message文本消息 
                case 1001:
                    break;
                //询问性别和生日信息
                case 3001:
                    if (end == true) {
                        const bottomList3001 = JSON.parse(localStorage.getItem("bottomList")) || {};
                        if (bottomList3001 && bottomList3001.gender) {
                            this.radioGender(bottomList3001.gender)
                        } else {
                            this.radioGender(getGenderLanguage(current_locale)[2])
                        }
                    }
                    break;
                //是否确认商推
                case 4001:
                    this.setState({
                        isShowYesNo: true
                    })
                    break;
                //展示测算的隐藏内容
                case 9100:
                    canSend = true;
                    this.setState(prevState => {
                        const updatedMessageList = prevState.messageList.map((item, i) => {
                            if (i === baziDiskIndex) {
                                return {
                                    ...item,
                                    isShowDetail: true,
                                    moreContent: content,
                                    showCursor: false,
                                };
                            }
                            return item;
                        });
                        return { messageList: updatedMessageList };
                    })
                    break;
            }
        }
        // type=hide
        const handleTypeHide = () => {
            if (end == false) {
                // if (/↵/.test(content)) {
                //     count = 0;
                //     newMessage = "";
                // } else {

                // }
                if (newMessage != "") {
                    newMessage += content; // 将消息内容添加到新消息中
                } else {
                    newMessage = content; // 否则设置新消息内容
                }
                if (count == 0) {
                    count++;
                    canSend = false; // 设置不可发送
                    this.updateNum = 0; // 重置更新计数器
                    handleMessageListUpdate({
                        type: "hide",
                        isUser: false,
                        avator: defaultImg,
                        botName: bot_name,
                        category: code,
                        content: newMessage,
                    }, false);
                } else {
                    let updateMessage = newMessage
                    if (/\n/.test(content)) {
                        // 检查是否包含除 \n 之外的字符
                        if (/[^\n]/.test(content)) {
                            updateMessage = newMessage.replace(/\n/g, "");
                        } else {
                            updateMessage = "";
                        }
                        count = 0;
                        newMessage = "";
                    }
                    // 更新消息列表中最后一条消息的内容
                    this.setState((prevState) => ({
                        messageList: prevState.messageList.map((message, index) => {
                            if (index === prevState.messageList.length - 1) {
                                if (updateMessage != "") {
                                    return {
                                        ...message,
                                        content: updateMessage,
                                    };
                                }
                            }
                            return message;
                        })
                    }), () => {
                        // 更新计数器并根据情况滚动到底部或刷新滚动区域
                        this.updateNum++;
                        if (!this.state.isBottomVisible && this.updateNum % 20 === 0) {
                            this.scrollToBottom();
                        }

                    });
                }
            } else if (end == true) {
                handleMessageListUpdate({
                    type: "hide",
                    isUser: false,
                    avator: defaultImg,
                    botName: bot_name,
                    category: code,
                    content: content,
                }, true);
                //设置可以发送消息
                canSend = true;
                // 重置新消息和计数器
                newMessage = "";
                count = 0;
                this.updateNum = 0;
            }
        }
        //type=bazi_pic
        const handleTypeBaZiPic = () => {
            if (code == 9030) {
                handleMessageListUpdate({
                    type: "bazi_pic",
                    category: code,
                    isUser: false,
                    avator: defaultImg,
                    botName: bot_name,
                    isShowDetail: false,
                    showCursor: false,
                    moreContent: '',
                    content: JSON.parse(content),
                }, true);
            } else if (code == 9031) {
                canSend = true;
                this.setState(prevState => {
                    const updatedMessageList = prevState.messageList.map((item, i) => {
                        if (i === baziDiskIndex) {
                            return {
                                ...item,
                                isShowDetail: true,
                                moreContent: content,
                                showCursor: false,
                            };
                        }
                        return item;
                    });
                    return { messageList: updatedMessageList };
                })
            }
        }
        // type=sku
        const handleTypeSku = () => {
            handleMessageListUpdate({
                type: "sku",
                isUser: false,
                avator: defaultImg,
                botName: bot_name,
                content: JSON.parse(content),
            }, true);
            //获取pc端商品数据
            this.pcHttpSku(JSON.parse(content))
        }
        // 逻辑新
        switch (type) {
            //hello 招呼语样式展示
            case 'hello':
                this.setState({
                    isHelloView: true,
                })
                this.getTipsList('', 'first_stage')
                break;
            //聊天session_id的返回
            case 'session_id':
                handleTypeSessionId();
                break;
            //头部 角色名称返回
            case 'FortuneTellerName':
                handleTypeFortuneTellerName();
                break;
            //文本消息类型 end标识(true消息结束 false消息正在返回)
            case 'string':
                handleTypeString();
                break;
            //商品消息
            case 'sku':
                handleTypeSku();
                break;
            //折行消息
            case 'hide':
                handleTypeHide();
                break;
            case 'bazi_pic':
                handleTypeBaZiPic();
                break;
            case 'stop_audio':
                isStopAudio = true;
                break;
            //显示点赞拉踩
            case 'diss':
                handleMessageListUpdate({
                    type: "diss",
                    category: code,
                    content: content,
                    msg: messageData.msg,
                    zan: false,
                    cai: false,
                }, true);
                break;
        }
    };
    // 判断最后一个元素是否可见
    isLastElementVisible = () => {
        if (this.messagesContainerRef.current) {
            const lastElement = this.messagesContainerRef.current.lastElementChild;
            if (lastElement) {
                const rect = lastElement.getBoundingClientRect();
                return rect.bottom <= window.innerHeight;
            }
        }
        return false;
    }
    //选择性别
    radioGender = (name) => {
        isChoose = false
        if (name) {
            const groupName = 'gender';
            const selectedValue = name;
            clickBottom.push(1);
            this.updateBottomBar(groupName, selectedValue, false);

            ReactGA.gtag('set', 'user_properties', {
                custom_gender: selectedValue
            })
            this.pushGa4('userInfo', 'choice', 'gender')
        }
    }
    //八字 选择器点击事件
    chooseBaZi = (event) => {
        isChoose = false
        if (event.target.classList.contains('chooseBirthday')) {
            if (this.datePickerRef.current) {
                this.datePickerRef.current.open();
            }
        } else if (event.target.classList.contains('chooseGender')) {
            if (this.pickerGenderRef.current) {
                this.pickerGenderRef.current.open();
            }
        }
    }
    handleGenderConfirm = (value) => {
        if (value) {
            if (isChoose) {
                this.changeBottomBar('gender', value)
            } else {
                clickBottom.push(1);
                this.updateBottomBar('gender', value, false, true);
            }
            ReactGA.gtag('set', 'user_properties', {
                custom_gender: value
            })
            this.pushGa4('userInfo', 'choice', 'gender')
        }
    }
    //选择出生日期(星座/八字)
    handleBirthdayConfirm = (value, isPCChoose) => {
        let date = new Date(value);
        var obj = {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            date: date.getDate(),
            hours: date.getHours(),
            minutes: date.getMinutes()
        }
        Object.keys(obj).forEach(key => {
            if (obj[key] < 10) obj[key] = `0${obj[key]}`
        })
        const dateValue = obj.year + '-' + obj.month + '-' + obj.date + ' ' + obj.hours + ':' + obj.minutes + ':00'
        if (isChoose || isPCChoose) {
            this.changeBottomBar('birthday', dateValue)
        } else {
            clickBottom.push(2);
            this.updateBottomBar('birthday', dateValue, false, true)
        }
        ReactGA.gtag('set', 'user_properties', {
            custom_birthday: dateValue,
            custom_age: ageUtils.calculateAge(dateValue)
        })
        this.pushGa4('userInfo', 'choice', 'birthday')
        this.pushGa4('userInfo', 'choice', 'age')

    };
    //更新底部标签展示 并 提交
    updateBottomBar = (groupName, selectedValue, showMessage) => {
        //在星座逻辑中选择星座和生日 需要前端展示一下假消息
        if (showMessage) {
            const newMessage = {
                content: selectedValue,
                isUser: true,
                type: 'string',
            };
            const aiMessage = {
                content: '',
                isUser: false,
                type: 'loading',
                avator: defaultImg,
                botName: this.state.botName,
            };
            this.setState(prevState => ({
                messageList: [...prevState.messageList, newMessage, aiMessage],
            }), () => {
                this.scrollToBottom();
                this.uploadCache();
            });
        }
        selectedOptions[groupName] = selectedValue;
        // 更新选中的值 按顺序添加
        let bottomItems = [];
        let choosetag = [{ code: 'gender', value: '' }, { code: 'birthday', value: '' }];
        if (selectedOptions['gender'] !== '') {
            bottomItems.push(selectedOptions['gender']);
        }
        if (selectedOptions['birthday'] !== '') {
            bottomItems.push(selectedOptions['birthday']);
        }
        if (clickBottom.length != 0) {
            if (clickBottom.indexOf(1) != -1) {
                choosetag[0].value = selectedOptions['gender'];
            }
            if (clickBottom.indexOf(2) != -1) {
                choosetag[1].value = selectedOptions['birthday'];
            }
        }
        this.setState({
            bottomItems: bottomItems,
            bottomItemsShow: true,
            choosetag: choosetag,
        }, () => {
            localStorage.setItem("bottomList", JSON.stringify(selectedOptions))
        });
        //发送给后端
        const { userId, userName, userAge, userAvatar, birthplace, preferenceTags, ws } = this.state;
        const genderOr = {
            user_id: userId,
            user_name: userName,
            user_age: userAge,
            user_gender: selectedOptions['gender'],
            blood_type: '',
            zodiac: '',
            birthday: selectedOptions['birthday'],
            user_avatar: userAvatar,
            birthplace: birthplace,
            preference_tags: preferenceTags,
        };
        ws.send(JSON.stringify(genderOr));
    };

    //修改消息后 更新底部标签展示 并 提交
    changeBottomBar = (groupName, selectedValue) => {
        selectedOptions[groupName] = selectedValue;
        // 更新选中的值 按顺序添加
        let bottomItems = [];
        if (selectedOptions['gender'] !== '') {
            bottomItems.push(selectedOptions['gender']);
        }
        if (selectedOptions['birthday'] !== '') {
            bottomItems.push(selectedOptions['birthday']);
        }
        this.setState({
            bottomItems: bottomItems,
            bottomItemsShow: true,
        }, () => {
            localStorage.setItem("bottomList", JSON.stringify(selectedOptions))
        });
        //发送给后端
        const { userId, userName, userAge, userAvatar, birthplace, preferenceTags, ws } = this.state;
        const genderOr = {
            user_id: userId,
            user_name: userName,
            user_age: userAge,
            user_gender: selectedOptions['gender'],
            blood_type: '',
            zodiac: '',
            birthday: selectedOptions['birthday'],
            user_avatar: userAvatar,
            birthplace: birthplace,
            preference_tags: preferenceTags,
        };
        ws.send(JSON.stringify(genderOr));
    }
    //清除选中标签
    clearChooseTag = () => {
        if (clickBottom.length != 0) {
            clickBottom = [];
        }
        this.setState({
            choosetag: []
        })
    }
    //滚动到底部
    scrollToBottom = () => {
        const container = this.messagesContainerRef.current
        if (container) {
            container.scrollTop = container.scrollHeight;
        }
    }
    //发送消息
    sendMessage(messageContent, asrText) {
        if (this.state.isHelloView) {
            this.setState({
                isHelloView: false,
            })
        }
        if (this.popoverRef.current) {
            this.popoverRef.current.hide();
        }
        const { ws } = this.state;
        canSend = false;
        const userMessage = {
            content: messageContent,
            isUser: true,
            type: 'string',
        };
        const aiMessage = {
            content: '',
            isUser: false,
            type: 'loading',
            avator: defaultImg,
            botName: this.state.botName,
        };
        this.setState(prevState => ({
            showCursor: true,
            showSend: false,
            messageList: [...prevState.messageList, userMessage, aiMessage],
        }), () => {
            if (asrText) {
                ws.send(asrText)
            } else {
                ws.send(messageContent);
            }
            this.scrollToBottom();
            this.uploadCache();
        });
    }
    //上传缓存
    uploadCache() {
        let loaclMessageList = this.state.messageList
        loaclMessageList = loaclMessageList.filter(message => message.type !== "loading");
        while (loaclMessageList.length >= 20) {
            loaclMessageList.shift();
        }
        localStorage.setItem("bazimessageList", JSON.stringify(loaclMessageList));
        localStorage.setItem("sessionId", this.state.sessionId)
        first_message = false;
    }
    //触发事件
    pushGa4(category, action, label) {
        ReactGA.gtag('event', action, {
            event_category: category,
            event_label: label,
            value: 1,
        })
    };
    //是否同意
    sendBtnYesNo = (value) => {
        let content;
        if (value == 'yes') {
            content = 'Yes'
            this.setState({
                isShowYesNo: false,
            })
        } else if (value == 'no') {
            content = 'No'
            this.setState({
                isShowYesNo: false,
            })
        }
        const userMessage = {
            content: content,
            isUser: true,
            type: 'string',
        };
        const aiMessage = {
            content: '',
            isUser: false,
            type: 'loading',
            avator: defaultImg,
            botName: this.state.botName,
        };
        this.setState(prevState => ({
            showCursor: true,
            showSend: false,
            messageList: [...prevState.messageList, userMessage, aiMessage],
        }), () => {
            this.state.ws.send(value);
            this.scrollToBottom();
            this.uploadCache();
        });
    }
    //修改基础信息
    changeInfo = (item, index) => {
        if (this.state.choosetag.length != 0){
            return;
        }
        isChoose = true
        if (index == 0) {
            if (this.pickerGenderRef.current) {
                this.pickerGenderRef.current.open()
            }
        }
        if (index == 1) {
            if (this.datePickerRef.current) {
                this.datePickerRef.current.open()
            }
        }
    }
    changeInfoPC = (item, index) => {
        if (this.state.choosetag.length != 0){
            return;
        }
        if (index == 0) {
            const menuItems = gender_columns.map((item, index) => ({
                key: index,
                label: item,
            }));

            const menu = (
                <Menu onClick={this.handleMenuGenderClick} items={menuItems} />
            );
            this.setState({
                DropdownVisible: true,
                menu: menu
            })
        }
        if (index == 1) {
            this.setState({
                datePcVisible: true,
                DropdownVisible: false,
            })
        }
    }
    handleMenuGenderClick = (e) => {
        this.changeBottomBar('gender', gender_columns[e.key])
        this.setState({
            DropdownVisible: false,
        })
    }
    handleOk = (value) => {
        this.handleBirthdayConfirm(value, true)
        this.setState({
            datePcVisible: false,
        });
    }
    handleOpenChange = () => {
        this.setState({
            datePcVisible: false,
        });
    }
    //显示八字盘解读信息
    showMore = (index, md5_hash, cesuan_hide_key) => {
        //当前没有内容输出的时候 可以触发展示星盘解读信息
        if (canSend) {
            if (md5_hash) {
                //this.state.ws.send('{"md5_hash": "' + md5_hash + '"}');
                this.setState({
                    sendEmailVisible: true,
                })
                email_hash = md5_hash
            }
            if (cesuan_hide_key != '') {
                canSend = false;
                this.state.ws.send('{"cesuan_hide_key": "' + cesuan_hide_key + '"}');
            }
            baziDiskIndex = index;
            this.setState(prevState => {
                const updatedMessageList = prevState.messageList.map((item, i) => {
                    if (i === index) {
                        return {
                            ...item,
                            isShowDetail: true,
                        };
                    }
                    return item;
                });
                return { messageList: updatedMessageList };
            })
        }
    }
    hideEmailPop = () => {
        this.setState({
            sendEmailVisible: false,
        })
        this.setState(prevState => {
            const updatedMessageList = prevState.messageList.map((item, i) => {
                if (i === baziDiskIndex) {
                    return {
                        ...item,
                        isShowDetail: false,
                    };
                }
                return item;
            });
            return { messageList: updatedMessageList };
        })
    }
    handleEmailChange = (event) => {
        const email = event.target.value;
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const isValidEmail = !emailPattern.test(email);
        this.setState({
            email,
            isValidEmail,
        })
    };
    handleEmailSubmit = () => {
        this.state.ws.send('{"email": "' + this.state.email + '","md5_hash": "' + email_hash + '"}');
        this.setState({
            sendEmailVisible: false,
        })
        this.setState(prevState => {
            const updatedMessageList = prevState.messageList.map((item, i) => {
                if (i === baziDiskIndex) {
                    return {
                        ...item,
                        isShowDetail: true,
                    };
                }
                return item;
            });
            return { messageList: updatedMessageList };
        })
    }
    showVoiceView = () => {
        this.requestMicrophoneAccess();
    }
    //拉起麦克风权限
    requestMicrophoneAccess = async () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            console.error('getUserMedia is not supported in this browser.');
            alert('Your browser does not support microphone access function. Please change your browser or check the environment configuration.');
            return;
        }
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            stream.getTracks().forEach(track => track.stop());
            this.setState({
                isShowVoice: true,
            })
        } catch (error) {
            console.error('Microphone access denied or error occurred:', error);
        }
    };
    hideVoiceView = () => {
        this.setState({
            isShowVoice: false,
        })
    }
    startRecording = () => {
        recordText = ""
        this.setState({
            isRecording: true,
            vadDelay: 100000,
        })
        this.stopAudio(true);
    }
    stopRecording = () => {
        this.setState({
            isRecording: false
        })
    }
    cancelRecording = () => {
        this.setState({
            isRecording: false
        })
    }
    //触发播放语音
    playAudio = (content, index) => {
        if (debounceStopAudio) {
            debounceStopAudio = false;
            setTimeout(() => {
                debounceStopAudio = true;
            }, 1000);
            if (canSend) {
                this.stopAudio(true)
                this.state.ws.send('{"text": "' + content + '","speaker_name":"Asia_Male_James_Hong"}')
                this.setState({
                    playAudioIndex: index,
                    showAudioLoading: true
                })
            }
        }
    }
    //触发停止播放语音
    stopAudio = (stop) => {
        if (debounceStopAudio) {
            debounceStopAudio = false;
            setTimeout(() => {
                debounceStopAudio = true;
            }, 1000);
            if (source) {
                source.stop();
                source = null;
                audioQueue = [];
            }
            if (stop) {
                this.state.ws.send('{"is_stop_tts":"stop_tts"}')
            }
            this.setState({
                playAudioIndex: -1,
                showAudioLoading: false
            })
            isStopAudio = false
        }
    }
    onDataAvailable = (data) => {
        recordText = data
    }
    onVolumeValue = (value) => {
        this.setState({
            volume: value
        })
    }
    onStop = (data) => {
        //静默时间导致停止通知语音控件复原
        this.setState({ vadEnd: true, isRecording: false, showAudioLoading: true })
        if (recordText != '' && recordText.length != 0) {
            if (this.state.isShowASR) {
                const asrText = `{"text":"${recordText}","speaker_name":"Asia_Male_James_Hong","tts_code_message_set":"asr_return_voice_and_text"}`
                this.sendMessage(recordText, asrText)
            } else {
                this.sendMessage(recordText)
            }
        }else {
            console.log("录音结束，没有录音内容")
            //如果asr 识别的内容为空 则重新开启录音
            if (this.state.isShowASR){
                setTimeout(() => {
                    this.startAsrRecord()
                }, 1000);
            }
        }
    }
    inputTips = (text, index) => {
        if (index == 2) {
            this.inputFieldRef.current.focus()
            this.setState({
                inputValue: ''
            })
        } else {
            this.setState({
                inputValue: text
            })
        }
    }
    //联想词提示
    getTipsList = (user_input, stage) => {
        axios({
            method: 'get',
            url: httpUrl + '/api/associative_words',
            params: {
                "user_id": this.state.userId,
                "session_id": this.state.sessionId,
                "user_gender": this.state.userGender,
                "preference_tags": this.state.preferenceTags,
                "language_type": language_type,
                "user_input": user_input,
                "stage": stage,
            }
        })
            .then(response => {
                this.setState({
                    tipsList: response.data.data
                })
            })
            .catch(error => {
                console.error(error);
            });

    }
    //商品详情iframe
    showProductDetail = (url) => {
        this.setState({
            iframeSrc: url,
            isShowProduct: true
        })
    }
    //点赞拉踩
    confirmDiss = (index, content, status_int) => {
        axios({
            method: 'post',
            url: httpUrl + '/api/dtc_like_and_dislike',
            data: {
                "user_id": this.state.userId,
                "session_id": this.state.sessionId,
                "user_gender": this.state.userGender,
                "preference_tags": this.state.preferenceTags,
                "language_type": language_type,
                "content": content,
                "status_int": status_int,
            }
        })
            .then(response => {
                if (response.data.status_code == 200) {
                    this.setState(prevState => {
                        const updatedMessageList = prevState.messageList.map((item, i) => {
                            if (i === index) {
                                switch (status_int) {
                                    case 1:
                                        return {
                                            ...item,
                                            zan: true,
                                        };
                                    case 2:
                                        return {
                                            ...item,
                                            cai: true,
                                        };
                                    case 3:
                                        return {
                                            ...item,
                                            zan: false,
                                        }
                                    case 4:
                                        return {
                                            ...item,
                                            cai: false,
                                        }

                                }
                            }
                            return item;
                        });
                        return { messageList: updatedMessageList };
                    })
                }
            })
            .catch(error => {
                console.error(error);
            });
    }
    //换一批商品
    changeGoods = (value) => {
        const userMessage = {
            content: getOtherConfig(current_locale)[4],
            isUser: true,
            type: 'string',
        };
        const aiMessage = {
            content: '',
            isUser: false,
            type: 'loading',
            avator: defaultImg,
            botName: this.state.botName,
        };
        this.setState(prevState => ({
            showCursor: true,
            showSend: false,
            messageList: [...prevState.messageList, userMessage, aiMessage],
        }), () => {
            this.state.ws.send(value);
            this.scrollToBottom();
            this.uploadCache();
        });
    }
    //asr开始录音
    startAsrRecord = (value) => {
        this.stopAudio(true);
        recordText = ""
        this.setState({
            isRecording: true,
            vadDelay: value,
        })
    }
    roleClick = () => {
        //打开ASR 并且开启录音
        recordText = ''
        this.setState({
            isShowASR: true,
            isRecording: true,
            vadDelay: 2000,
        })
    }
    onCloseAsrPop = () => {
        this.setState({
            isShowASR: false,
            isRecording: false,
        })
        this.stopAudio(true)
    }
    //渲染组件
    render() {
        return (
            <div className="main_chat">
                <img src={zhanxingbackground} className="zhanxingbackground" />
                <div className="chat">
                    <PageTitle title="Ba zi" />
                    {this.state.isHelloView && (
                        <HelloMessage avatar={baziPerson} messagelist={this.state.messageList} name={'Chan'}
                            background={zhanxingbackground} inputTips={this.inputTips}
                            tipsList={this.state.tipsList} />
                    )}

                    <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', display: this.state.isHelloView ? 'none' : 'flex' }}>
                        <HeadTitle avator={defaultImg}
                            //botName={this.state.FortuneTellerName}
                            //TODO
                            botName={"Chan"}
                            roleClick={this.roleClick}
                        />
                        <MessageBox messageList={this.state.messageList}
                            messagesContainerRef={this.messagesContainerRef}
                            chooseBaZi={this.chooseBaZi}
                            radioGender={this.radioGender}
                            showCursor={this.state.showCursor}
                            isChatLoading={this.state.isChatLoading}
                            handleBirthdayConfirm={this.handleBirthdayConfirm}
                            handleGenderConfirm={this.handleGenderConfirm}
                            showMore={this.showMore}
                            playAudio={this.playAudio}
                            stopAudio={this.stopAudio}
                            playAudioIndex={this.state.playAudioIndex}
                            showAudioLoading={this.state.showAudioLoading}
                            showProductDetail={this.showProductDetail}
                            confirmDiss={this.confirmDiss}
                            changeGoods={this.changeGoods}
                        />
                    </div>

                    {
                        this.state.isBottomVisible && (
                            <div className="floating_button"
                                onClick={this.scrollToBottom}>
                                <img src={floatingBtn} />
                            </div>
                        )
                    }
                    <Popup
                        visible={this.state.sendEmailVisible}
                        closeable
                        className="sendEmail"
                        position='center'
                        round
                        closeOnClickOverlay={false}
                        onClose={this.hideEmailPop}
                    >
                        <div className="sendEmail_box">
                            <span className="email_title">Fortune report</span>
                            <span className="email_content">Your fortune interpretation report is ready and will be sent to your email via email! 🔮</span>
                            <input
                                className="email_input"
                                type="email"
                                value={this.state.email}
                                onChange={this.handleEmailChange}
                                placeholder={getSendEmail(current_locale)[1]}
                            />
                            <Button className="send_email_btn" color='#B89054' type='primary' block disabled={this.state.isValidEmail} onClick={this.handleEmailSubmit}>
                                {getSendEmail(current_locale)[0]}
                            </Button>

                        </div>
                    </Popup>
                    <SendingBox sendMessage={this.sendMessage}
                        bottomItemsShow={this.state.bottomItemsShow}
                        bottomItems={this.state.bottomItems}
                        showSend={this.state.showSend}
                        inputFieldRef={this.inputFieldRef}
                        inputValue={this.state.inputValue}
                        choosetag={this.state.choosetag}
                        clearChooseTag={this.clearChooseTag}
                        sendBtnYesNo={this.sendBtnYesNo}
                        isShowYesNo={this.state.isShowYesNo}
                        changeInfo={this.changeInfo}
                        changeInfoPC={this.changeInfoPC}
                        DropdownVisible={this.state.DropdownVisible}
                        menu={this.state.menu}
                        datePcVisible={this.state.datePcVisible}
                        handleOk={this.handleOk}
                        handleOpenChange={this.handleOpenChange}
                        defaultValue={this.defaultValue}
                        isShowVoice={this.state.isShowVoice}
                        showVoiceView={this.showVoiceView}
                        hideVoiceView={this.hideVoiceView}
                        startRecording={this.startRecording}
                        stopRecording={this.stopRecording}
                        cancelRecording={this.cancelRecording}
                        volume={this.state.volume}
                        isRecording={this.state.isRecording}
                        onDataAvailable={this.onDataAvailable}
                        onVolumeValue={this.onVolumeValue}
                        onStop={this.onStop}
                        vadEnd={this.state.vadEnd}
                        popoverRef={this.popoverRef}
                        tipsList={this.state.tipsList}
                        getTipsList={this.getTipsList}
                        isHelloView={this.state.isHelloView}
                        vadDelay={this.state.vadDelay}
                    />
                    <Picker
                        ref={this.pickerGenderRef}
                        popup={{
                            round: true,
                        }}
                        value={gender_columns[0]}
                        title='select your gender'
                        placeholder={picker_language[2]}
                        cancelButtonText={picker_language[1]}
                        confirmButtonText={picker_language[0]}
                        columns={gender_columns}
                        onConfirm={this.handleGenderConfirm}
                    />
                    <DatetimePicker
                        ref={this.datePickerRef}
                        popup={{
                            round: true,
                        }}
                        type='datetime'
                        title='select your birthday'
                        minDate={new Date(1950, 0, 1)}
                        maxDate={new Date()}
                        cancelButtonText={picker_language[1]}
                        confirmButtonText={picker_language[0]}
                        value={this.state.birthday_values}
                        onConfirm={this.handleBirthdayConfirm}
                    />
                </div>
                <PcGoodsMessage content={this.state.product} />
                <Popup
                    visible={this.state.isShowProduct}
                    closeable
                    style={{ height: '90%' }}
                    position='bottom'
                    round
                    onClose={() => this.setState({ isShowProduct: false })}
                >
                    <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                        <iframe src={this.state.iframeSrc} style={{ width: '100%', height: '100%', marginTop: '50px', border: 'none' }}></iframe>
                    </div>
                </Popup>
                <Popup visible={this.state.isShowASR}
                    //closeable
                    style={{ height: '100%' }}
                    position='bottom'
                    onClose={() => { this.onCloseAsrPop() }} >
                    <Asr isRecording={this.state.isRecording}
                        onVolumeValue={this.state.volume}
                        startAsrRecord={this.startAsrRecord}
                        showAudioLoading={this.state.showAudioLoading}
                        onCloseAsrPop={this.onCloseAsrPop}
                    />
                </Popup>
            </div>
        );
    }
}
//消息体
class MessageBox extends React.Component {
    render() {
        const { messagesContainerRef, chooseBaZi, radioGender, isChatLoading, handleBirthdayConfirm, handleGenderConfirm, showMore, playAudio, stopAudio, playAudioIndex, showAudioLoading } = this.props;
        return (
            <div className="messageBox" ref={messagesContainerRef}>
                <Skeleton className="chat_skeleton" avatar loading={isChatLoading} ></Skeleton>
                {isChatLoading == false && (
                    this.props.messageList.map((messageData, index) => (
                        <div className="div_message_item" key={index}>
                            {messageData.isUser && messageData.type == 'string' && (
                                <UserMessage content={messageData.content} />
                            )}
                            {!messageData.isUser && (messageData.type == 'string' || messageData.type == 'hide') && (
                                <AiMessage
                                    length={this.props.messageList.length}
                                    index={index}
                                    avator={messageData.avator}
                                    botName={messageData.botName}
                                    content={messageData.content}
                                    category={messageData.category}
                                    radioGender={radioGender}
                                    chooseBaZi={chooseBaZi}
                                    showCursor={this.props.showCursor}
                                    locale={current_locale}
                                    handleBirthdayConfirm={handleBirthdayConfirm}
                                    handGenderConfirm={handleGenderConfirm}
                                    isShowDetail={messageData.isShowDetail}
                                    moreContent={messageData.moreContent}
                                    cesuan_hide_key={messageData.cesuan_hide_key}
                                    showMore={showMore}
                                    playAudio={playAudio}
                                    stopAudio={stopAudio}
                                    playAudioIndex={playAudioIndex}
                                    showAudioLoading={showAudioLoading} />
                            )}
                            {messageData.type == 'diss' && (
                                <DissMessage
                                    zan={messageData.zan}
                                    cai={messageData.cai}
                                    content={messageData.content}
                                    index={index}
                                    confirmDiss={this.props.confirmDiss}
                                    isShowChange={messageData.msg}
                                    changeGoods={this.props.changeGoods} />
                            )}
                            {!messageData.isUser && messageData.type == 'sku' && (
                                <GoodsMessage avator={messageData.avator}
                                    botName={messageData.botName}
                                    content={messageData.content}
                                    showProductDetail={this.props.showProductDetail}
                                />
                            )}
                            {!messageData.isUser && messageData.type == 'bazi_pic' && (
                                <StarDiskMessage
                                    length={this.props.messageList.length}
                                    index={index}
                                    isShowDetail={messageData.isShowDetail}
                                    content={messageData.content}
                                    moreContent={messageData.moreContent}
                                    showCursor={messageData.showCursor}
                                    showMore={showMore}
                                />
                            )}
                            {!messageData.isUser && messageData.type == 'loading' && (
                                <LoadingMessage avator={messageData.avator}
                                    botName={messageData.botName}
                                    loading_text={getOtherConfig(current_locale)[0]} />
                            )}
                        </div>
                    ))
                )}
            </div>
        );
    }
}
//底部input+item
class SendingBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: props.inputValue,
            isComposing: false
        };
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.inputValue !== this.props.inputValue) {
            this.setState({ inputValue: this.props.inputValue });
        }
        if (prevState.inputValue !== this.state.inputValue) {
            this.adjustTextareaHeight();
        }
    }
    adjustTextareaHeight() {
        const textarea = this.props.inputFieldRef.current;
        if (textarea) {
            textarea.style.height = 'auto'; // 重置高度以计算新的高度
            const scrollHeight = textarea.scrollHeight;
            const maxHeight = parseFloat(window.getComputedStyle(textarea).lineHeight) * 4; // 最大高度为4行
            textarea.style.height = `${Math.min(scrollHeight, maxHeight)}px`; // 动态调整高度
        }
    }

    handleInputChange = (event) => {
        this.setState({ inputValue: event.target.value });
        if (!this.props.isHelloView) {
            // 清除之前的定时器
            if (this.debounceTimeout) {
                clearTimeout(this.debounceTimeout);
            }
            const value = event.target.value;
            if (value && value.trim()) {
                // 设置新的定时器
                this.debounceTimeout = setTimeout(() => {
                    if (this.state.inputValue.trim()) {
                        this.props.getTipsList(value, 'second_stage')
                        this.props.popoverRef.current.show();
                    }
                }, 500);
            } else {
                this.props.popoverRef.current.hide();
            }
        }

    };

    //选择联想提示词
    handleSelectTips = (value) => {
        this.setState({ inputValue: value });
        this.props.popoverRef.current.hide();
    }

    //监听enter
    handleKeyDown = (event) => {
        if (event.key === 'Enter' && event.shiftKey) {
            // 如果按下的是 Shift + 回车，则执行换行操作
            return;
        }
        if (event.key === 'Enter' && !this.state.isComposing) {
            // 如果按下的是回车，并且没有在输入中文，则发送消息
            // 阻止默认的换行行为
            event.preventDefault();
            this.handleSendMessage();
        }
    }
    handleCompositionStart = () => {
        this.setState({ isComposing: true });
    };

    handleCompositionEnd = (e) => {
        this.setState({
            isComposing: false,
            inputValue: e.target.value
        });
    };

    handleSendMessage = () => {
        const { sendMessage } = this.props;
        let message = this.state.inputValue;
        if ((this.props.choosetag.length != 0 || this.state.inputValue.trim() !== "") && canSend) {
            if (this.props.choosetag.length != 0) {
                if (this.props.choosetag[0].value != "") {
                    message = (message ? message + "\n" : "") + getSendUserInfoLanguage(current_locale)[0] + this.props.choosetag[0].value;
                }
                if (this.props.choosetag[1].value != "") {
                    message = (message ? message + "\n" : "") + getSendUserInfoLanguage(current_locale)[2] + this.props.choosetag[1].value;
                }
            }
            sendMessage(message);
            this.setState({ inputValue: "" }, () => {
                this.props.clearChooseTag();
            });
        }
    };
    render() {
        const { inputValue } = this.state;
        const { bottomItemsShow, bottomItems, showSend, inputFieldRef, choosetag, sendBtnYesNo, isShowYesNo, changeInfo, changeInfoPC, DropdownVisible, menu, datePcVisible, handleOk, handleOpenChange, defaultValue, isShowVoice, showVoiceView, hideVoiceView, startRecording, stopRecording, cancelRecording, isRecording, onDataAvailable, volume, onStop, onVolumeValue, vadEnd, vadDelay } = this.props;
        return (
            <div className="sendingBox">
                {bottomItemsShow && (
                    <>
                        <div className="bottom_item">
                            {bottomItems.map((item, index) => (
                                <span translate="no" key={index} onClick={() => changeInfo(item, index)}>{item}</span>
                            ))}
                            <img src={editInfo} style={{ height: '100%' }}
                                onClick={() => Toast({
                                    message: 'Click on the label to modify the information',
                                    position: 'bottom',
                                })}></img>
                        </div>
                        <DatePicker
                            className="datePicker_pc"
                            showTime
                            open={datePcVisible}
                            defaultValue={defaultValue}
                            popupStyle={{ visibility: 'visible', zIndex: 9999 }}
                            style={{ width: 0, height: 0, opacity: 0 }}
                            onOk={handleOk}
                            onOpenChange={handleOpenChange}
                        />
                        <Dropdown
                            overlay={menu}
                            trigger={'click'}
                            open={DropdownVisible}
                            overlayStyle={{ zIndex: 9999 }}
                        >
                            <div className="bottom_item_pc">
                                {bottomItems.map((item, index) => (
                                    <span translate="no" key={index} onClick={() => changeInfoPC(item, index)}>{item}</span>
                                ))}
                                <img src={editInfo} style={{ height: '100%' }}
                                    onClick={() => Toast({
                                        message: 'Click on the label to modify the information',
                                        position: 'bottom',
                                    })}></img>
                            </div>
                        </Dropdown>
                    </>
                )}
                {isShowVoice && (
                    <ColorChangeButton
                        stopRecording={stopRecording}
                        cancelRecording={cancelRecording}
                        startRecording={startRecording}
                        hideVoiceView={hideVoiceView}
                        vadEnd={vadEnd}
                        volume={volume} />
                )}
                <AudioRecorder
                    isRecording={isRecording}
                    onDataAvailable={onDataAvailable}
                    language_type={current_locale}
                    onStop={onStop}
                    onVolumeValue={onVolumeValue}
                    vadDelay={vadDelay} />
                {!isShowVoice && (
                    <Popover ref={this.props.popoverRef}
                        placement="top-start"
                        trigger="manual"
                        offset={[15, 10]}
                        reference={
                            <div className="bottom_border">
                                <div className="text_message_box">
                                    {choosetag.length != 0 && (
                                        <div className="text_message_item">
                                            {choosetag.map((item, index) => (
                                                item.value != "" && (
                                                    <span translate="no" className="text_message_item_span" key={index}>{item.value}</span>
                                                )
                                            ))}
                                        </div>
                                    )}
                                    {isShowYesNo && (
                                        <div className="radio_option">
                                            <Radio.Group className="radio_group" direction="horizontal" checkedColor="#2153F9" iconSize="16px" onChange={sendBtnYesNo}>
                                                <Radio className="radio" name={'yes'}>Yes</Radio>
                                                <Radio className="radio" name={'no'} >No</Radio>
                                            </Radio.Group>
                                        </div>
                                    )}
                                    {!isShowYesNo && (
                                        <textarea
                                            ref={inputFieldRef}
                                            className="text_message"
                                            value={inputValue}
                                            onChange={this.handleInputChange}
                                            placeholder={getOtherConfig(current_locale)[1]}
                                            onKeyDown={this.handleKeyDown} // 监听键盘事件
                                            onCompositionStart={this.handleCompositionStart}
                                            onCompositionEnd={this.handleCompositionEnd}
                                            rows={1}
                                        />
                                    )}
                                </div>
                                {!isShowYesNo && (
                                    <img
                                        className="voice_button"
                                        src={voice}
                                        onClick={showVoiceView}
                                    />
                                )}
                                {showSend ? (
                                    <img
                                        className={`send_button ${choosetag.length != 0 ? 'buttonScale' : ''}`}
                                        onClick={this.handleSendMessage}
                                        src={(inputValue.trim() !== '' || choosetag.length != 0) ? sendBtnYes : sendBtnNo}
                                    />
                                ) : (
                                    <div className="send_button_stop_box">
                                        <img
                                            className="send_button_loading"
                                            src={sendBtnIng}
                                        />
                                        <div style={{ width: '10px', height: '10px', backgroundColor: '#2c2c2c', borderRadius: '2px', position: 'absolute', display: 'none' }}></div>
                                    </div>
                                )}
                            </div>
                        }>
                        <div className="tips_list">
                            {this.props.tipsList.map((item, index) => (
                                <div className="tips_item" key={index} onClick={() => this.handleSelectTips(item)}>
                                    {item}
                                </div>
                            ))}
                        </div>
                    </Popover>
                )}
            </div>
        );
    }
}