import axios from 'axios';

// 创建一个 Axios 实例
const axiosInstance = axios.create({
    //baseURL: 'http://54.176.193.190:8801', // 封装基础 URL
    timeout: 60000, // 设置请求超时为1分钟
    credentials: 'include', // 在所有请求中携带 Cookie
});

// 请求拦截器
axiosInstance.interceptors.request.use(
    config => {
        // 可以在这里配置请求头或其他请求设置
        return config; // 返回请求配置
    },
    error => {
        // 在请求发送前处理错误
        console.error('Request error:', error);
        return Promise.reject(error);
    }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
    response => {
        // 对返回结果进行处理，通常检查状态码
        if (response.status === 200) {
            return response.data; // 成功时返回响应数据
        } else {
            throw new Error(`Request failed with status code ${response.status}`);
        }
    },
    error => {
        // 处理响应错误
        console.error('Response error:', error);
        return Promise.reject(error);
    }
);

// 定义 GET 请求方法
export const axiosGet = async (url, params = {}, headers = {}) => {
    return axiosInstance.get(url, {
        params,
        withCredentials: true,
        headers
    });
};

// 定义 POST 请求方法
export const axiosPost = async (url, data, headers = {}) => {
    return axiosInstance.post(url, data, {
        withCredentials: true,
        headers
    });
};
