
import { React, useState } from 'react';
import './index.scss';
import { Popup } from 'react-vant';
import Star from "../chat/index";
import Bazi from "../chat/bazi/index"
import Taluo from "../chat/tarot_chat/index"

const Home = ({ }) => {
    const [visible, setVisible] = useState(false); // 用于跟踪卡片的点击的索引
    const [type, setType] = useState('');

    var home_overlay = document.getElementsByClassName('home_overlay'); function adjustIframeHeight() {
        Array.from(home_overlay).forEach(function (element) {
            element.style.height = window.innerHeight + 'px';
        });
    }
    adjustIframeHeight(); // 初始设置高度
    window.addEventListener('resize', adjustIframeHeight);

    return (
        <div className="home_page">
            <div className='home_overlay' style={{ height: window.innerHeight }}>
                <div className='head'>
                        <img className='logo' src={require('../../assets/img/home_logo.png')}></img>
                        <p className='title'>LuckyBuy</p>
                    </div>
                    <div className='img_main'>
                        <img className='person_img1' src={require('../../assets/img/white_person.png')}></img>
                        <img className='person_img2' src={require('../../assets/img/lading.png')}></img>
                        <img className='person_img3' src={require('../../assets/img/chengliangming.png')}></img>
                        <div className='content'>
                            {'Welcome to LuckyBuy. You’re striking gold by meeting me.'} <br />
                            {'Please choose what you would like to learn about: Astrology, Tarot, or Bazi. Let us help reveal your destiny.'}
                        </div>
                    </div>
                    <div className='btn_main'>
                        <div className='btn astrology' onClick={() => { setVisible(true), setType('astrology') }}>
                            <p>Astrology</p>
                        </div>
                        <div className='btn taluo' onClick={() => { setVisible(true), setType('taluo') }}>
                            <p>Tarot</p>
                        </div>
                        <div className='btn bazi' onClick={() => { setVisible(true), setType('bazi') }}>
                            <p>Ba zi</p>
                        </div>
                    </div>
            </div>

            <Popup position="bottom" closeable visible={visible} style={{ height: window.innerHeight }} onClose={() => setVisible(false)}>
                {type === 'astrology' && <Star></Star>}
                {type === 'taluo' && <Taluo></Taluo>}
                {type === 'bazi' && <Bazi></Bazi>}
            </Popup>
        </div>
    );
}

export default Home;
