import React, { useRef, useEffect, useState } from "react";
import "./Asr.scss";
import Lottie from 'lottie-react';
import flowJson from "../../../../assets/Flow.json";
import sikaoJson from "../../../../assets/sikao.json";

const Asr = ({ isRecording, onVolumeValue, startAsrRecord, showAudioLoading, onCloseAsrPop }) => {
  const asr_stop_audio = require("../../../../assets/img/asr_stop_audio.png")
  const asr_close = require("../../../../assets/img/asr_close.png")
  // 创建一个包含 8 个 ref 的数组
  const animationRefs = useRef([]);
  const lottieRef = useRef(null);

  useEffect(() => {
    if (isRecording) {
      console.log("开始录音", isRecording)
    } else {
      console.log("结束录音", isRecording)
    }
  }, [isRecording])

  useEffect(() => {
    updateAnimationStyles(onVolumeValue, isRecording)
  }, [onVolumeValue, isRecording])

  // 初始化 ref 数组，仅在组件首次渲染时执行
  if (animationRefs.current.length !== 8) {
    animationRefs.current = Array.from({ length: 8 }, () => React.createRef());
  }
  const updateAnimationStyles = (volume, isRecording) => {
    const heightCoefficient = 0.5; // 调整系数
    let maxHeight = 0; // 最大高度
    let minHeight = 0; // 最小高度

    if (volume === 0 || isRecording == false) {
      animationRefs.current.forEach((ref) => {
        if (ref.current) {
          ref.current.style.transition = 'height 0.1s ease'; // 设置过渡样式
          ref.current.style.height = '10px'; // 复原高度
        }
      });
      return; // 直接返回
    }

    // 更新每个动画元素的样式
    animationRefs.current.forEach((ref, index) => {
      if (ref.current) {
        let baseHeight;
        if ([0, 3, 7].includes(index)) {
          maxHeight = 35; // 最大高度
          minHeight = 10; // 最小高度
          baseHeight = volume * heightCoefficient; // 第1、4、8个元素
        } else if ([1, 4, 6].includes(index)) {
          maxHeight = 50; // 最大高度
          minHeight = 10; // 最小高度
          baseHeight = (volume + 20) * heightCoefficient; // 第2、5、7个元素
        } else if ([2, 5].includes(index)) {
          maxHeight = 50; // 最大高度
          minHeight = 10; // 最小高度
          baseHeight = (volume + 40) * heightCoefficient; // 第3、6个元素
        }
        // 添加随机扰动
        const transitionDuration = `${(Math.random() * 0.2 + 0.1).toFixed(2)}s`; // 生成随机过渡时间
        const randomFactor = Math.random() * 10 - 5; // -5到5之间的随机数
        const newHeight = `${Math.min(maxHeight, Math.max(minHeight, baseHeight + randomFactor))}px`; // 限制高度范围
        ref.current.style.transition = `height ${transitionDuration} ease`; // 设置过渡样式
        ref.current.style.height = newHeight;
      }
    });
  };
  const stopAndStartRecord = () => {
    startAsrRecord(2000)
  }

  const closeAsr = () => {
    onCloseAsrPop()
  }

  const handleComplete = () => {
    console.log('Animation Completed');
  };

  return (
    <div className="asr_background">
      {isRecording && <span style={{ fontSize: '20px', color: 'white' }}>Listening...</span>}
      {!isRecording && showAudioLoading &&
        // <span style={{ fontSize: '20px', color: 'white' }}>Hold on for a few seconds...</span>
        <div className="animation">
          <Lottie
            animationData={sikaoJson}
            loop={true}
            autoplay={true}
            lottieRef={lottieRef}
            onComplete={handleComplete}
          />
        </div>
      }
      {!isRecording && !showAudioLoading &&
        <div className="animation">
          <Lottie
            animationData={flowJson}
            loop={true}
            autoplay={true}
            lottieRef={lottieRef}
            onComplete={handleComplete}
          />
        </div>}
      <div className="asr_bottom">
        <img className="asr_start" style={{ opacity: !isRecording && !showAudioLoading ? 1 : 0 }} src={asr_stop_audio} onClick={() => stopAndStartRecord()}>
        </img>
        <div>
          <div className='animation'
            style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {animationRefs.current.map((ref, index) => (
              <span
                key={index}
                ref={animationRefs.current[index]}
                style={{
                  width: '8px',
                  height: '10px',
                  backgroundColor: 'white',
                  borderRadius: '50px',
                  margin: '4px',
                  boxSizing: 'border-box'
                }}
              />
            ))}
          </div>
        </div>
        <img className="asr_stop" src={asr_close} onClick={() => closeAsr()}>
        </img>
      </div>
    </div>
  );
};
export default Asr;