import React, { useState, useEffect, useRef } from 'react';
import './index.scss';
import { httpUrl } from '../../utils/apiService.js'
import axios from 'axios';

const TarotFortune = () => {
    // 使用状态变量来跟踪当前动画步骤
    const [currentStep, setCurrentStep] = useState('default');
    // 跟踪用户点击的卡牌索引
    const [selectedCardIndex, setSelectedCardIndex] = useState(null);
    //用于洗牌完成显示 展牌
    const [animationFinished, setAnimationFinished] = useState(false);

    const [cardImg, setCardImg] = useState(null);
    const cardRefs = useRef([]); // 使用 useRef 来创建保存卡牌引用的数组

    // 动态生成关键帧动画
    const generateKeyframes = (animationName) => {
        const keyframes = `
            @keyframes ${animationName} {
                0% {
                    // transform: translate(0px, 0px) rotate(0deg) ;
                }

                15% {
                    // transform: translate(0px, 0px) rotate(0deg) ;
                    transform: translate(${getRandomNumber()}px, ${getRandomNumber()}px) rotate(${getRandomNumber()}deg) ;
                }

                35% {
                    transform: translate(${getRandomNumber()}px, ${getRandomNumber()}px) rotate(${getRandomNumber()}deg) ;
                }

                50%,
                65% {
                    transform: translate(${getRandomNumberRough()}px, ${getRandomNumberRough()}px) rotate(${getRandomNumberRough()}deg) ;
                }

                85% {
                    transform: translate(${getRandomNumber()}px, ${getRandomNumber()}px) rotate(${getRandomNumber()}deg) ;
                }

                100% {
                    transform: translate(0px, 0px) rotate(0deg) );
                }
            }
        `;
        const style = document.createElement('style');
        style.textContent = keyframes;
        document.head.appendChild(style);
    };

    // 获取一个随机数 用于洗牌平移范围
    const getRandomNumber = () => {
        return Math.random() * 500 - 250;
    };
    // 获取一个随机数 用于牌面动画延迟时间
    const getRandomNumberRange = (min, max) => {
        return Math.random() * (max - min) + min;
    };
    // 获取一个随机数 用于洗牌中间的不规则范围
    const getRandomNumberRough = () => {
        return Math.random() * 20 - 10;
    }

    // 应用动态生成的关键帧动画到元素
    const applyAnimationToElement = (element, animationName) => {
        element.style.animation = `${animationName} 3s ease-in-out ${getRandomNumberRange(0.01, 0.4)}s both`; // 调整动画持续时间等参数
    };

    const getCardData = () => {
        axios({
            method: 'post',
            url: httpUrl + '/api/dtc_kaluo',
            data: {
                "rec_count": 1
            }
        })
            .then(function (response) {
                setCardImg(response.data.data[0].new_img)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    useEffect(() => {
        getCardData()
        if (!animationFinished) {
            const parentElement = document.querySelectorAll('.card-item-shuffle');
            parentElement.forEach((element, index) => {
                element.style.transform = `translateY(${index * -0.5}px) translateX(${index * -0.5}px)`;
            });
        }
        setTimeout(() => {
            if (!animationFinished) {
                cardRefs.current.forEach((ref, index) => {
                    const animationName = `step-1-${index + 1}`;
                    generateKeyframes(animationName);
                    applyAnimationToElement(ref, animationName);
                });
            }
        }, 1000);
    }, []);

    // 在组件挂载时启动默认动画
    useEffect(() => {
        // 设置计时器，在默认动画结束后切换到 step-1
        const timer = setTimeout(() => {
            setAnimationFinished(true);
            setCurrentStep('step-2');
        }, 5000);
        // 清除计时器以避免内存泄漏
        return () => clearTimeout(timer);
    }, []);

    // 点击卡牌时触发 step-4 动画
    const handleCardClick = (index) => {
        // 当点击卡牌时，将 selectedCardIndex 设置为点击的卡牌索引
        setSelectedCardIndex(index);
        // 设置动画步骤为 step-4
        setCurrentStep('step-4');
    };

    return (
        <div className="page-container">
            <div className="main-container">
                <div className="card-container">
                    {Array.from({ length: 31 }, (_, index) => (
                        <div
                            key={index}
                            // 如果卡牌索引与选中的卡牌索引匹配，则应用 step-4，否则应用 step-3
                            className={`card-item ${selectedCardIndex === index ? 'step-4' : currentStep === 'step-4' ? 'step-3' : currentStep}`}
                            onClick={() => handleCardClick(index)} // 点击卡牌时调用 handleCardClick
                        >
                            <div className="card van-image">
                                <img src="https://cdn.seeronnet.com/web-tf/dist/emotionResult/img/tarot.8795537c.png" className="van-image__img" alt="Tarot Card" />
                            </div>
                        </div>
                    ))}
                    {!animationFinished && Array.from({ length: 31 }, (_, index) => (
                        <div
                            key={index}
                            ref={(element) => (cardRefs.current[index] = element)} // 使用 ref 属性将每个卡牌元素保存到 cardRefs 数组中
                            // 如果卡牌索引与选中的卡牌索引匹配，则应用 step-4，否则应用 step-3
                            className={`card-item-shuffle`}>
                            <div className="card van-image">
                                <img src="https://cdn.seeronnet.com/web-tf/dist/emotionResult/img/tarot.8795537c.png" className="van-image__img" alt="Tarot Card" />
                            </div>
                        </div>
                    ))}
                    <div className={`draw-item ${currentStep === 'step-4' ? 'step-4-opposite' : ''}`}>
                        <div className="card van-image">
                            <img src={`data:image/jpeg;base64,${cardImg}`} className="van-image__img" alt="Tarot Card" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TarotFortune;
