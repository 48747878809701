import React from "react";
import { Loading } from "react-vant";
import "../animate/slide_top.css"
import "../animate/slide_in_left_loading.css";


const LoadingMessage = ({avator,botName,loading_text}) => {
    return (
        <div className="ai_content slide-in-left-loading">
            {/* <img src={avator} /> */}
            <div className="ai_loading">
            <Loading className="loading" type="ball" />
            <span className="loading_text">{loading_text}</span>
            </div>
        </div>
    )
}
export default LoadingMessage;