import React from 'react';
import './CircleAnimation.css'; // 导入样式文件

class CircleAnimation extends React.Component {
  render() {
    return (
      <div className="circle"></div>
    );
  }
}

export default CircleAnimation;
