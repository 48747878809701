import { locale } from "dayjs";

const starSigns = {
    'zh-CN': ['白羊座', '金牛座', '双子座', '巨蟹座', '狮子座', '处女座', '天秤座', '天蝎座', '射手座', '摩羯座', '水瓶座', '双鱼座'],
    'zh-TW': ['白羊座', '金牛座', '雙子座', '巨蟹座', '獅子座', '處女座', '天秤座', '天蠍座', '射手座', '摩羯座', '水瓶座', '雙魚座'],
    'ja': ['おひつじ座', 'おうし座', 'ふたご座', 'かに座', 'しし座', 'おとめ座', 'てんびん座', 'さそり座', 'いて座', 'やぎ座', 'みずがめ座', 'うお座'],
    'ko': ['양자리', '황소자리', '쌍둥이자리', '게자리', '사자자리', '처녀자리', '천칭자리', '전갈자리', '사수자리', '염소자리', '물병자리', '물고기자리'],
    'es': ['Aries', 'Tauro', 'Géminis', 'Cáncer', 'Leo', 'Virgo', 'Libra', 'Escorpio', 'Sagitario', 'Capricornio', 'Acuario', 'Piscis'],
    'en': ['Aries', 'Taurus', 'Gemini', 'Cancer', 'Leo', 'Virgo', 'Libra', 'Scorpio', 'Sagittarius', 'Capricorn', 'Aquarius', 'Pisces']
};

const localeDescriptions = {
    'zh-CN': "中文",
    'zh-TW': "繁体中文",
    'ja': "日语",
    'ko': "韩语",
    'es': "西班牙语",
    'en': "英语"
};

const pickerPhrases = {
    'zh-CN': ["确定", "取消", "请选择"],
    'zh-TW': ["確定", "取消", "請選擇"],
    'ja': ["決定", "キャンセル", "選択してください"],
    'ko': ["확인", "취소", "선택하세요"],
    'es': ["Aceptar", "Cancelar", "Por favor seleccione"],
    'en': ["OK", "Cancel", "Please Select"]
}

const userInfoLanguage = {
    'zh-CN': [" · 选择您的", " · 选择您的性别", " · 选择您的血型", "星座", "生日", "男", "女","性别"],
    'zh-TW': [" · 選擇您的", " · 選擇您的性別", " · 選擇您的血型", "星座", "生日", "男", "女","性別"],
    'ja': [" · あなたのを選択してください", " · あなたの性別を選択してください", " · あなたの血液型を選択してください", "星座", "誕生日", "男", "女","せいべつ"],
    'ko': [" · 선택하십시오", " · 성별 선택", " · 혈액형을 선택하십시오", "별자리", "생일", "남성", "여성","성별"],
    'es': [" · Seleccione su", " · Seleccione su género", " · Seleccione su tipo de sangre", "signo del zodiaco", "cumpleaños", "Masculino", "Femenino","género"],
    'en': [" · Select your", " · Select your gender", " · Select your blood type", "star sign", "birthday", "Male", "Female","gender"],
};

const genderLanguage = {
    'zh-CN': ["男", "女","其他"],
    'zh-TW': ["男", "女","其他"],
    'ja': [ "男", "女","その他"],
    'ko': ["남성", "여성","다른"],
    'es': ["Masculino", "Femenino","Otro"],
    'en': ["Male", "Female","Other"],
}

const sendUserInfoLanguage = {
    'zh-CN': [" · 我是 ", "  · 我的血型是 ", " · 我的生日是 "],
    'zh-TW': [" · 我是 ", "  · 我的血型是 ", " · 我的生日是 "],
    'ja': [" · 私は ", "  · 私の血液型は ", " · 私の誕生日は "],
    'ko': [" · 나는 ", "  · 내 혈액형은 ", " · 내 생일은 "],
    'es': [" · Soy ", "  · Mi tipo de sangre es ", " · Mi cumpleaños es "],
    'en': [" · I am ", "  · My blood type is ", " · My birthday is "],
}

const otherConfig = {
    'zh-CN': ["回复中...", "我想...", "查看更多", "继续沟通","更换一批产品"],
    'zh-TW': ["回覆中...", "我想...", "查看更多", "繼續溝通","更換一批產品"],
    'ja': ["返信中...", "私は...", "もっと見る", "コミュニケーションを続ける","商品のバッチを交換する"],
    'ko': ["답장 중...", "나는 ...", "더 보기", "계속 소통하다","상품을 교환","일괄 상품 교체"],
    'es': ["Respondiendo...", "Quiero...", "VER MÁS", "continuar comunicándose","Reemplazar un lote de bienes"],
    'en': ["Replying...", "I want...", "VIEW MORE", "continue to communicate","Replace a batch of products"],
}

const tarotPopup = {
    'zh-CN': ['请放松心情，专注当下。让内心的直觉引导你，准备好时从牌堆中抽牌。', '开始抽牌',"开始"],
    'zh-TW': ['請放鬆心情，專注當下。讓內心的直覺引導你，準備好時從牌堆中抽牌。', '開始抽牌',"開始"],
    'ja': ['心を落ち着かせ、今この瞬間に集中してください。直感に従い、準備ができたらデッキからカードを引いてください。', 'カードを引く',"始め"],
    'ko': ['마음을 편안하게 하고 현재에 집중하세요. 직감을 따르고 준비가 되면 덱에서 카드를 뽑으세요.', '카드 뽑기 시작',"카드 뽑기 시작","시작"],
    'es': ['Relájate y concéntrate en el momento presente. Deja que tu intuición te guíe y, cuando estés listo, saca una carta del mazo.', 'Comenzar a sacar cartas',"Comenzar"],
    'en': ['Relax and focus on the present moment. Let your intuition guide you, and when you‘re ready, draw a card from the deck.', 'Start Drawing Cards',"Start"],
}

const tarotTime = {
    'zh-CN': ['过去','现在','未来'],
    'zh-TW': ['過去', '現在', '未來'],
    'ja': ['過去', '現在', '未来'],
    'ko': ['과거', '현재', '미래'],
    'es': ['Pasado', 'Presente', 'Futuro'],
    'en': ['Past', 'Present', 'Future'],
}

const sendEmail = {
    'zh-CN': ['提交','请输入邮箱地址'],
    'zh-TW': ['提交','請輸入郵箱地址'],
    'ja': ['送信','メールアドレスを入力してください'],
    'ko': ['제출','이메일 주소를 입력하세요'],
    'es': ['Enviar','Por favor ingrese su dirección de correo electrónico'],
    'en': ['Submit','Please enter your email address'],
}

const getSendEmail = (locale) => {
    if (/en/.test(locale)) {
        return sendEmail['en'];
    }
    if (/es/.test(locale)) {
        return sendEmail['es'];
    }
    if (/ko/.test(locale)) {
        return sendEmail['ko'];
    }
    return sendEmail[locale] || sendEmail['en'];
}

const getTarotTime = (locale) => {
    if (/en/.test(locale)) {
        return tarotTime['en'];
    }
    if (/es/.test(locale)) {
        return tarotTime['es'];
    }
    if (/ko/.test(locale)) {
        return tarotTime['ko'];
    }
    return tarotTime[locale] || tarotTime['en'];
}
const getTarotPopup = (locale) => {
    if (/en/.test(locale)) {
        return tarotPopup['en'];
    }
    if (/es/.test(locale)) {
        return tarotPopup['es'];
    }
    if (/ko/.test(locale)) {
        return tarotPopup['ko'];
    }
    return tarotPopup[locale] || tarotPopup['en'];
}

const getOtherConfig = (locale) => {
    if (/en/.test(locale)) {
        return otherConfig['en'];
    }
    if (/es/.test(locale)) {
        return otherConfig['es'];
    }
    if (/ko/.test(locale)) {
        return otherConfig['ko'];
    }
    return otherConfig[locale] || otherConfig['en'];
}

const getSendUserInfoLanguage = (locale) => {
    if (/en/.test(locale)) {
        return sendUserInfoLanguage['en'];
    }
    if (/es/.test(locale)) {
        return sendUserInfoLanguage['es'];
    }
    if (/ko/.test(locale)) {
        return sendUserInfoLanguage['ko'];
    }
    return sendUserInfoLanguage[locale] || sendUserInfoLanguage['en'];
}

const getUserInfoLanguage = (locale) => {
    if (/en/.test(locale)) {
        return userInfoLanguage['en'];
    }
    if (/es/.test(locale)) {
        return userInfoLanguage['es'];
    }
    if (/ko/.test(locale)) {
        return userInfoLanguage['ko'];
    }
    return userInfoLanguage[locale] || userInfoLanguage['en'];
}

const getGenderLanguage = (locale) => {
    if (/en/.test(locale)) {
        return genderLanguage['en'];
    }
    if (/es/.test(locale)) {
        return genderLanguage['es'];
    }
    if (/ko/.test(locale)) {
        return genderLanguage['ko'];
    }
    return genderLanguage[locale] || genderLanguage['en'];
};

const getPickerPhrases = (locale) => {
    if (/en/.test(locale)) {
        return pickerPhrases['en'];
    }
    if (/es/.test(locale)) {
        return pickerPhrases['es'];
    }
    if (/ko/.test(locale)) {
        return pickerPhrases['ko'];
    }
    return pickerPhrases[locale] || pickerPhrases['en'];
}

const getLocaleDescription = (locale) => {
    if (/en/.test(locale)) {
        return localeDescriptions['en'];
    }
    if (/es/.test(locale)) {
        return localeDescriptions['es'];
    }
    if (/ko/.test(locale)) {
        return localeDescriptions['ko'];
    }
    return localeDescriptions[locale] || localeDescriptions['en'];
};

const getStarSigns = (locale) => {
    if (/en/.test(locale)) {
        return starSigns['en'];
    }
    if (/es/.test(locale)) {
        return starSigns['es'];
    }
    if (/ko/.test(locale)) {
        return starSigns['ko'];
    }
    return starSigns[locale] || starSigns['en']; // 如果 locale 不匹配，则使用默认英文
};

export {
    getStarSigns,
    getLocaleDescription,
    getPickerPhrases,
    getUserInfoLanguage,
    getSendUserInfoLanguage,
    getOtherConfig,
    getTarotPopup,
    getTarotTime,
    getGenderLanguage,
    getSendEmail,
};

