// apiservice.js

// 定义 WebSocket URL
const wsUrl = 'wss://wss.futurecuex.com/api/dtc_chatbot/ws'; // 用于生产环境
// const wsUrl = 'ws://192.168.3.4:8000/api/dtc_chatbot/ws'; // 本地
// const wsUrl = 'ws://192.168.3.15:8000/api/dtc_chatbot/ws'; // 本地

//WordPress
const wcUrl = 'http://54.176.193.190:8801';
//http
// const httpUrl = 'http://192.168.3.15:8000';
// const httpUrl = 'http://172.26.32.61:8000';
const httpUrl = 'https://api.futurecuex.com';

// 导出 WebSocket URL 常量
export { wsUrl,wcUrl,httpUrl };

// 其他 API 服务相关代码可以在这里定义
// 例如 HTTP 请求方法、其他 API 路径等