import React, { useState, useEffect, useRef } from 'react';
import '../tarot_3/tarot_time.scss';
import { httpUrl } from '../../utils/apiService.js'
import axios from 'axios';
import { getOtherConfig } from '../chat/subview/locale.js';

const tarot_Img = require("../../assets/img/tarot_resver.png");

const TarotFortune = ({ sendTarot, showTarot, locale }) => {
    const shuffleCardRefs = useRef([]); // 用于创建洗牌动画的ref数组
    const unfoldCardRefs = useRef([]);// 用于创建展牌动画的ref数组
    const cardsMeaningRef = useRef(null);
    const pageRef = useRef(null);
    const [clickedIndexes, setClickedIndexes] = useState([]); // 用于跟踪卡片的点击的索引

    //  cardImg 用于显示那张牌的图片
    const [selector, setSelector] = useState(null);
    const [cardImg, setCardImg] = useState([]);
    const [cardName, setCardName] = useState(null);
    const [cardFace, setCardFace] = useState(null);
    const [cardMeaning, setCardMeaning] = useState(null);

    //用于判断是否选择过
    const [isSelected, setIsSelected] = useState(false);

    //用于洗牌完成显示 展牌
    const [animationFinished, setAnimationFinished] = useState(false);

    const [current_locale, setCurrentLocale] = useState('');
    const totalItemBoxes = 78; // 假设有78个itemBox
    const delay = 0.022; // 每个展牌的延迟时间
    const translatexy = 0.2; // 一把牌的偏移量
    //点击跳转聊天
    const goChat = () => {
        sendTarot("tarot")
    }

    //点击卡牌事件处理
    const handleItemClick = (index) => {
        //数组内包含点击的索引，则不执行点击事件
        if (clickedIndexes.includes(index)) {
            return;
        }
        // 根据点击次数确定应该应用的CSS类
        let newClickedIndexes = [...clickedIndexes];
        if (newClickedIndexes.length == 0) {
            setSelector('zero');
        }
        if (newClickedIndexes.length >= 1) {
            return;
        } else {
            // 点击了卡片，添加到点击次数数组中
            newClickedIndexes.push(index);
            setClickedIndexes(newClickedIndexes);
            // 将点击的卡片平移到指定位置
            const clickedCard = unfoldCardRefs.current[index];
            if (clickedCard) {
                clickedCard.style.animationDelay = '0s';
                clickedCard.style.animation = 'moveAndFlip 2s forwards';
                clickedCard.style.zIndex = '200';
                setTimeout(() => {
                    // 设置点击卡片为选中状态，并使用这个标识来隐藏所有卡牌，显示描述信息
                    setIsSelected(true)
                    document.querySelector('.cards_list').style.display = 'flex';
                    // 延时两秒后显示 执行向上平移的动画（由于内容一屏显示不全）
                    setTimeout(() => {
                        setSelector('move-top')
                        const parentElement = document.querySelectorAll('.unfold-cards-item');
                        const totalItems = parentElement.length;
                        for (let i = 0; i < totalItems; i++) {
                            parentElement[i].style.display = 'none';
                        }
                        
                    }, 2000)
                }, 1000)
            }
        }

    };

    //获取信息
    const handleUserId = () => {
        setCurrentLocale(locale)
        getCardData(locale);

        if (!animationFinished) {
            //添加默认一把卡牌的偏移量 用于看起来立体
            const parentElement = document.querySelectorAll('.shuffle-item');
            parentElement.forEach((element, index) => {
                element.style.transform = `translateY(${index * -translatexy}px)`;
            });
        }
        setTimeout(() => {
            if (!animationFinished) {
                const parentElement = document.querySelectorAll('.shuffle-item');
                parentElement.forEach((element, index) => {
                    element.style.transform = `translateX(${index * - translatexy}px) translateY(${window.innerHeight / 2 + 120 + (index * -translatexy)}px) `;
                    element.style.transitionDuration = `${index * delay}s`;
                });
                setTimeout(() => {
                    //开始洗牌动画 并给每张卡牌添加不同的动画名称 step-1—()
                    shuffleCardRefs.current.forEach((ref, index) => {
                        const animationName = `step-1-${index + 1}`;
                        generateKeyframes(animationName, index);
                        applyAnimationToElement(ref, animationName);
                    });
                }, 2000);
            }
        }, 500);

        // 监听洗牌事件完成
        const timer = setTimeout(() => {
            setAnimationFinished(true);
        }, 6000);
        return () => clearTimeout(timer);

    }

    // 动态生成洗牌的关键帧动画
    const generateKeyframes = (animationName, index) => {
        let translateX, translate1X, translate2X, translate3X, translateY;
        if (index < 26) {
            translateX = -100;
            translate1X = 0;
            translate2X = -100;
            translate3X = 0;
            translateY = window.innerHeight / 2 + 120 + (index * -translatexy);
        } else if (26 <= index && index < 52) {
            translateX = 0;
            translate1X = -100;
            translate2X = 0;
            translate3X = -100;
            translateY = window.innerHeight / 2 + 120 + (index * -translatexy);
        } else if (52 <= index && index < 78) {
            translateX = 100;
            translate1X = 100;
            translate2X = 100;
            translate3X = 100;
            translateY = window.innerHeight / 2 + 120 + (index * -translatexy);
        }
        const keyframes = `
            @keyframes ${animationName} {
                0% {
                    transform: translateX(${index * -translatexy}px) translateY(${window.innerHeight / 2 + 120 + (index * -translatexy)}px); 
                }
                15% {
                    transform: translateX(${translateX}px) translateY(${translateY}px); 
                }
    
                30% {
                    transform: translateX(${translate1X}px) translateY(${translateY}px);
                }
    
                45%,
                60% {
                    transform: translateX(${index * -translatexy}px) translateY(${window.innerHeight / 2 + 120 + (index * -translatexy)}px);
                }
                75% {
                    transform: translateX(${translate2X}px) translateY(${translateY}px);
                }
                90% {
                    transform: translateX(${translate3X}px) translateY(${translateY}px);

                }
                100% {
                    transform: translateX(${index * -translatexy}px) translateY(${window.innerHeight / 2 + 120 + (index * -translatexy)}px);
                }
            }
        `;
        const style = document.createElement('style');
        style.textContent = keyframes;
        document.head.appendChild(style);
    };

    // 将动态关键帧洗牌动画添加到元素
    const applyAnimationToElement = (element, animationName) => {
        element.style.animation = `${animationName} 3s ease-in-out `; // 调整动画持续时间等参数
    };

    // 请求卡牌数据
    const getCardData = (language_type) => {
        const tarot_data = JSON.parse(localStorage.getItem("tarot_one"))
        setCardImg(tarot_data.taluodata[0].new_img)
        setCardName(tarot_data.taluodata[0].name)
        setCardFace(tarot_data.taluodata[0].face_text)
        setCardMeaning(tarot_data.taluodata[0].wen_desc)
        // axios({
        //     method: 'post',
        //     url: httpUrl + '/api/dtc_kaluo',
        //     data: {
        //         "rec_count": 1,
        //         "language_type": language_type
        //     }
        // })
        //     .then(function (response) {
        //         setCardImg(response.data.data[0].new_img)
        //         setCardName(response.data.data[0].name)
        //         setCardFace(response.data.data[0].face_text)
        //         setCardMeaning(response.data.data[0].wen_desc)

        //         const tarot_one_data = JSON.stringify(processData(response.data.data))
        //         localStorage.setItem("tarot_one", tarot_one_data);
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });
    }
    // 处理请求的卡牌数据 重新整合
    const processData = (rawData) => {
        // 将 data 替换为 taluodata，并删除每个对象中的 image 属性
        const processedData = rawData.map(({ image, ...rest }) => rest);
        return { taluodata: processedData };
    };


    // 添加 showMoreButton 的点击事件监听器
    const handleClick = () => {
        const cardsMeaning = document.querySelector('.cards_meaning');
        const showMoreButton = document.querySelector('.show_more')
        cardsMeaning.style.webkitLineClamp = 'initial'; // 移除行数限制
        showMoreButton.style.display = 'none'; // 隐藏"显示更多"按钮
    };

    useEffect(() => {
        if (showTarot) {
            handleUserId();
        }
        //handleUserId();
    }, []);

    useEffect(() => {
        // 当 animationFinished 状态变为 true 时执行展牌操作
        if (animationFinished) {
            const parentElement = document.querySelectorAll('.unfold-cards-item');
            const totalItems = parentElement.length;
            const distanceBetweenItems = 10; // 调整每个元素之间的距离
            for (let i = 0; i < totalItems; i++) {
                if (i < 20) {
                    parentElement[i].style.transform = `rotate(${-90}deg) translate(${200 - (i * distanceBetweenItems)}px, ${-120}px) scale(0.65)`;
                    parentElement[i].style.animationDelay = `${i * delay}s`;
                } else if (20 <= i && i <= 55) {
                    parentElement[i].style.transform = `rotate(${-90 - ((i - 20) * 5)}deg) translate(${0}px, ${-120}px) scale(0.65)`;
                    parentElement[i].style.animationDelay = `${i * delay}s`;
                } else if (i >= 56) {
                    parentElement[i].style.transform = `rotate(${90}deg) translate(-${(i - 56) * distanceBetweenItems}px, ${-120}px) scale(0.65)`;
                    parentElement[i].style.animationDelay = `${i * delay}s`;
                }
            }
        }
    }, [animationFinished]);

    return (
        <div id="page-container" ref={pageRef}>
            {!animationFinished && (
                <div className="shuffle-container">
                    <div className="shuffle-subcontainer">
                        {Array.from({ length: totalItemBoxes }, (_, index) => (
                            <div
                                key={index}
                                ref={(element) => (shuffleCardRefs.current[index] = element)}
                                className={`shuffle-item`}
                            >
                                <div className="shuffle-item-img">
                                    <img src={tarot_Img} className="shuffle-item-imgbg" alt="Tarot Card Bg" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {animationFinished && (
                <div className={`unfold-cards-subcontainer`}>
                    {Array.from({ length: totalItemBoxes }).map((_, index) => (
                        <div
                            key={index}
                            className={`unfold-cards-item ${isSelected ? 'unfold-hide-Animation' : 'unfold-default-Animation'}`}
                            onClick={() => handleItemClick(index)}
                            ref={(element) => (unfoldCardRefs.current[index] = element)}
                        ></div>
                    ))}
                    <img
                        className={`open-cards-img open-card-animation-${selector}`}
                        src={`data:image/jpeg;base64,${cardImg}`}
                    />
                    <div className={`cards_list ${!isSelected ? 'unfold-hide-Animation' : 'unfold-show-Animation'}`}>
                        <div className={`cards_content`}>
                            <div className="cards_content_name_face" >
                                <p className='cards_content_name'>{cardName}</p>
                                <p className='cards_content_face'>{cardFace}</p>
                            </div>
                            <div className='cards_meaning' ref={cardsMeaningRef}>
                                {cardMeaning}{cardMeaning}{cardMeaning}{cardMeaning}{cardMeaning}{cardMeaning}
                            </div>
                            <div className='show_more' onClick={handleClick}>
                                {getOtherConfig(current_locale)[2]}
                            </div>
                            <div className={`go_chat`}
                                onClick={() => goChat()}>
                                {getOtherConfig(current_locale)[3]}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TarotFortune;
