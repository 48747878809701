import React, { useEffect, useState } from "react";
import { Rate, Skeleton, Button } from "react-vant";
import 'animate.css';
import "../animate/slide_top.css"
import ReactGA from "react-ga4";
import shoppingBackground from "../../../assets/img/undraw_web_shopping_re_owap@1x.svg";
import Rating from "./Rating";
const PcGoodsMessage = ({ content }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timer); // 清除定时器
    }, [content]);

    const getImageSrc = (item) => {
        try {
            return item;
        } catch (error) {
            // 如果没有找到对应的图像，则返回默认图像路径
            //return require('../../assets/dtc/default.png');
        }
    };
    const redirectToGoodsPage = ({ goodsUrl, goodsId }) => {
        // 根据商品信息执行跳转操作，例如使用 window.location.href 或者 Vue Router
        //window.location.href = goodsUrl;
        window.open(goodsUrl, '_blank', 'noopener,noreferrer');
        pushGoogleData(goodsId, goodsUrl)
    };
    //上报谷歌数据
    const pushGoogleData = (goodsId, goodsUrl, goodsName) => {
        ReactGA.gtag("event", "view_item", {
            items: [
                {
                    item_id: goodsId,
                    item_name: goodsName,
                    location_id: goodsUrl,
                    index: 0,
                }
            ]
        });
    }

    return (
        <div className="goods_div">
            <div className="goods_content">
                {content.map((goods, index) =>
                    <div className="ai_content_commodity_background" key={index} onClick={() => redirectToGoodsPage({ goodsUrl: goods.detail_url, goodsId: content[0].skuid, goodsName: content[0].product_name })}>
                        <Skeleton row={1} round={false}
                            loading={loading}
                            className="pc_goods_skeleton">
                            <img className="goods_img" src={getImageSrc(goods.product_media)} />
                        </Skeleton>
                        <Skeleton
                            loading={loading}
                            row={2} rowHeight={20} round={true}
                            style={{ marginTop: '8px', width: '112px' }}>
                            <span className="goods_name"> {goods.product_name} </span>
                            <div className="rate">
                                <span className="rate_num">{goods.rate}</span>
                                <Rating rating={goods.rate} width={20} />
                            </div>
                            <div className="goods_price">
                                <span className="current_price">$ {goods.standard_price} </span>
                                {/* <span className="history_price">$ {goods.standard_price} </span> */}
                            </div>
                        </Skeleton>
                    </div>
                )}
            </div>
            {content.length == 0 && (
                <div style={{ width: '100%', height: '100%' , display: 'flex', alignItems:'center',justifyContent:'center' }}>
                    <img src={shoppingBackground} ></img>
                </div>
            )}
        </div>
    )
}
export default PcGoodsMessage;