import React, { useState } from "react";
import "../animate/slide_in_left.css";
const DissMessage = ({ zan, cai, content, index, confirmDiss, changeGoods, isShowChange }) => {

    const zanImg = require(`../../../assets/img/zan.png`);
    const caiImg = require(`../../../assets/img/cai.png`);
    const zanSelecterImg = require(`../../../assets/img/zan_selecter.png`);
    const caiSelecterImg = require(`../../../assets/img/cai_selecter.png`);
    const changeProducts = require(`../../../assets/img/change_products.png`);

    const [zanAnimate, setZanAnimate] = useState(false);
    const [caiAnimate, setCaiAnimate] = useState(false);
    const [changeAnimate, setChangeAnimate] = useState(false);
    const onClickZan = () => {
        //setZanAnimate(false)
        if (!zan) {
            confirmDiss(index, content, 1)
            setZanAnimate(true)
            setTimeout(() => { setZanAnimate(false) }, 500)
        } else {
            confirmDiss(index, content, 3)
        }
    }
    const onClickCai = () => {
        //
        if (!cai) {
            confirmDiss(index, content, 2)
            setCaiAnimate(true)
            setTimeout(() => { setCaiAnimate(false) }, 500)
        } else {
            confirmDiss(index, content, 4)
        }
    }

    const onClikChange = () => {
        changeGoods("Help_me_change_a_batch_of_goods")
        setChangeAnimate(true)
        setTimeout(() => { setChangeAnimate(false) },1000)
    }

    return (
        <div className="slide-in-left" style={{ width: '100%', height: '40px', display: 'flex', justifyContent: 'center', marginTop: '-10px' }}>
            <div className="zan_cai_div">
                {isShowChange && isShowChange == 'change' && (
                    <img src={changeProducts} alt="change"
                        className={`change_goods ${changeAnimate ? 'change-animation' : ''}`}
                        onClick={onClikChange} />
                )}
                <img src={zan ? zanSelecterImg : zanImg}
                    className={`zan ${zanAnimate ? 'zoom-animation' : ''}`}
                    alt="zan" onClick={onClickZan} />
                <img src={cai ? caiSelecterImg : caiImg}
                    className={`cai ${caiAnimate ? 'reset-zoom-animation' : ''}`}
                    alt="cai" onClick={onClickCai} />
            </div>
        </div>
    )
}
export default DissMessage;