// WebSocket.js

export default class WebSocketClient {
  constructor(url) {
    this.url = url
    this.socket = null
    this.onOpen = null
    this.onMessage = null
    this.onClose = null
    this.onError = null
  }

  connect() {
    this.socket = new WebSocket(this.url)
    this.socket.binaryType = 'arraybuffer'

    this.socket.onopen = () => {
      if (this.onOpen) {
        this.onOpen()
      }
    }

    this.socket.onmessage = (event) => {
      if (this.onMessage) {
        this.onMessage(event.data)
      }
    }

    this.socket.onclose = () => {
      if (this.onClose) {
        this.onClose()
      }
    }

    this.socket.onerror = (error) => {
      if (this.onError) {
        this.onError(error)
      }
    }
  }

  send(data) {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(data)
    } else {
      this.onError('WebSocket is not open. Cannot send data.')
    }
  }

  close(code,reason) {
    this.socket.close(code, reason)
  }
}
