import React, { useEffect, useState, useRef } from "react";
import { Rate, Skeleton, Button } from "react-vant";
import 'animate.css';
import "../animate/slide_top.css"
import "../animate/slide_in_left.css";
import ReactGA from "react-ga4";
import Rating from "./Rating.js";
const GoodsMessage = ({ content, showProductDetail }) => {
    const [loading, setLoading] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);
    const containerRef = useRef(null);
    const isManualScroll = useRef(false);
    const scrollTimeout = useRef(null);

    const leftImage = require(`../../../assets/img/left_goods.png`);
    const rightImage = require(`../../../assets/img/right_goods.png`);

    useEffect(() => {
        // 获取数据的代码...;
        setTimeout(() => {
            setLoading(false);
        }, 1500)
    }, []); // 空数组表示这个effect只在组件挂载时执行一次。

    const handleLeftClick = () => {
        if (containerRef.current) {
            isManualScroll.current = true;
            const newScrollPosition = Math.max(scrollPosition - 2, 0);
            setScrollPosition(newScrollPosition);
            console.log(newScrollPosition)
            containerRef.current.scrollBy({
                left: -containerRef.current.children[0].offsetWidth * 2,
                behavior: 'smooth'
            });
        }
    };

    const handleRightClick = () => {
        if (containerRef.current) {
            isManualScroll.current = true;
            const newScrollPosition = Math.min(scrollPosition + 2, content.length - 1);
            setScrollPosition(newScrollPosition);
            containerRef.current.scrollBy({
                left: containerRef.current.children[0].offsetWidth * 2,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        if (containerRef.current) {
            const handleScroll = () => {
                if (scrollTimeout.current) {
                    clearTimeout(scrollTimeout.current);
                }
                scrollTimeout.current = setTimeout(() => {
                    const newScrollPosition = Math.round(containerRef.current.scrollLeft / containerRef.current.children[0].offsetWidth);
                    setScrollPosition(newScrollPosition);
                    scrollTimeout.current = null;
                }, 100); // 设置延迟时间，单位为毫秒
            };
            containerRef.current.addEventListener('scroll', handleScroll);
            return () => {
                if (containerRef.current) {
                    containerRef.current.removeEventListener('scroll', handleScroll);
                }
                if (scrollTimeout.current) {
                    clearTimeout(scrollTimeout.current);
                }
            }
        }
    }, []);

    const getImageSrc = (item) => {
        try {
            return item;
            //return require(`../../../assets/dtc/20240402205.png`);
        } catch (error) {
            // 如果没有找到对应的图像，则返回默认图像路径
            //return require('../../assets/dtc/default.png');
        }
    };
    const redirectToGoodsPage = ({ goodsUrl, goodsId }) => {
        // 根据商品信息执行跳转操作，例如使用 window.location.href 或者 Vue Router
        //window.location.href = goodsUrl;
        //showProductDetail(goodsUrl)
        window.open(goodsUrl, '_blank', 'noopener,noreferrer');
        pushGoogleData(goodsId, goodsUrl)
    };

    //上报谷歌数据
    const pushGoogleData = (goodsId, goodsUrl, goodsName) => {
        ReactGA.gtag("event", "view_item", {
            items: [
                {
                    item_id: goodsId,
                    item_name: goodsName,
                    location_id: goodsUrl,
                    index: 0,
                }
            ]
        });
    }

    return (
        <div className="slide-in-left">
            <div className="highgoods_content">
                <div className="high">
                    <div className="high_top">
                        <img className="high_img" src={getImageSrc(content[0].product_media)} />
                        <div className="high_right">
                            <span className="high_name">{content[0].product_name}</span>
                            <div className="high_rate">
                                <span className="rate_num">{content[0].rate}</span>
                                <Rating
                                    rating={content[0].rate}
                                    width={20} />
                                <span className="tag_name">{content[0].product_category}</span>
                            </div>
                            <div className="high_price">
                                <img className="high_tag" src={require(`../../../assets/img/goods_tag.png`)} />
                                <span className="current_price">$ {content[0].standard_price}</span>
                            </div>
                        </div>
                    </div>
                    <span className="high_desc">
                        {content[0].product_narrative}
                    </span>
                    <Button color="#B89054" round shadow="1" className="high_button"
                        onClick={() => redirectToGoodsPage({ goodsUrl: content[0].detail_url, goodsId: content[0].skuid, goodsName: content[0].product_name })}>
                        View Details
                    </Button>
                </div>
            </div>
            <div className="goods_content" ref={containerRef}>
                {content.slice(1).map((goods, index) =>
                    <div key={index} onClick={() => redirectToGoodsPage({ goodsUrl: goods.detail_url, goodsId: goods.skuid, goodsName: goods.product_name })}>
                        <div className="ai_content_commodity_background">
                            <Skeleton row={0} title={0}
                                loading={loading}
                                style={{ background: '#FFFFFF', width: '100%', height: '166px', borderRadius: '15px', boxSizing: 'border-box' }}>
                                <img src={getImageSrc(goods.product_media)} />
                                <span className="tag_name">{goods.product_category}</span>
                                {/* <img src={getImageSrc("collect_no")} className="collect_no" />
                                <img src={getImageSrc("collect_yes")} className="collect_yes" /> */}
                            </Skeleton>
                            <Skeleton row={0} title={0}
                                loading={loading}
                                style={{ background: '#FFFFFF', width: '100%', height: '40px', marginTop: '5px', borderRadius: '10px', boxSizing: 'border-box' }}>
                                <span className="goods_name"> {goods.product_name} </span>
                                <div className="rate">
                                    <span className="rate_num">{goods.rate}</span>
                                    <Rating rating={goods.rate} width={16}/>
                                </div>
                                <div className="goods_price">
                                    <span className="current_price">$ {goods.standard_price} </span>
                                    {/* <span className="history_price">$ {goods.standard_price} </span> */}
                                </div>
                            </Skeleton>
                        </div>
                    </div>
                )}
                <img
                    src={leftImage}
                    className="left_image"
                    onClick={handleLeftClick}
                    style={{
                        visibility: scrollPosition > 0 ? 'visible' : 'hidden',
                        opacity: scrollPosition > 0 ? 1 : 0,
                        transition: 'opacity 0.5s ease-in-out'
                    }}
                />
                <img
                    src={rightImage}
                    className="right_image"
                    onClick={handleRightClick}
                    style={{
                        visibility: scrollPosition < content.length - 3 ? 'visible' : 'hidden',
                        opacity: scrollPosition < content.length - 3 ? 1 : 0,
                        transition: 'opacity 0.5s ease-in-out'
                    }}
                />
            </div>
            {/* <div className="zan_cai_div">
                <img src={zan} className="zan"></img>
                <img src={cai} className="cai"></img>
            </div> */}
        </div>
    )
}
export default GoodsMessage;