import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import { httpUrl } from '../../utils/apiService.js'
import axios from 'axios';


const TarotFortune = () => {
    const cardRefs = useRef([]); // 使用 useRef 来创建保存卡牌引用的数组
    const shuffleCardRefs = useRef([]);
    const [clickedIndexes, setClickedIndexes] = useState([]); // 用于跟踪每张卡片的点击次数的状态
    const [idnex0, setIndex0] = useState([]);
    const [cardImg0, setCardImg0] = useState([]);
    const [idnex1, setIdnex1] = useState([]);
    const [cardImg1, setCardImg1] = useState([]);
    const [idnex2, setIdnex2] = useState([]);
    const [cardImg2, setCardImg2] = useState([]);
    //用于洗牌完成显示 展牌
    const [animationFinished, setAnimationFinished] = useState(false);
    const totalItemBoxes = 78; // 假设有78个itemBox
    const animationDelayIncrement = 0.016; // 假设动画延迟的增量为0.016


    const handleItemClick = (index) => {
        if (clickedIndexes.includes(index)) {
            // 如果点击次数已达到3次，则不再处理点击事件
            return;
        }
        // 根据点击次数确定应该应用的CSS类
        let newClickedIndexes = [...clickedIndexes];
        if (newClickedIndexes.length == 0) {
            setIndex0(0);
        }
        if (newClickedIndexes.length == 1) {
            setIdnex1(1);
        }
        if (newClickedIndexes.length == 2) {
            setIdnex2(2);
        }
        if (newClickedIndexes.length === 3) {
            // 如果已经有3个点击过的卡片，则不再处理点击事件
            return;
        } else {
            // 点击了卡片，添加到点击次数数组中
            newClickedIndexes.push(index);
            setClickedIndexes(newClickedIndexes);

            // 将点击的卡片平移到指定位置
            const clickedCard = shuffleCardRefs.current[index];
            clickedCard.style.animationDelay = '0s';
        }

    };

    // 动态生成关键帧动画
    const generateKeyframes = (animationName) => {
        const keyframes = `
            @keyframes ${animationName} {
                0% {
                    //transform: translate(0px, 0px) rotate(0deg) translateZ(0px);
                }

                15% {
                    //transform: translate(0px, 0px) rotate(0deg) translateZ(0px);
                    transform: translate(${getRandomNumber()}px, ${getRandomNumber()}px) rotate(${getRandomNumber()}deg) translateZ(${getRandomNumber()}px);
                }

                35% {
                    transform: translate(${getRandomNumber()}px, ${getRandomNumber()}px) rotate(${getRandomNumber()}deg) translateZ(${getRandomNumber()}px);
                }

                50%,
                65% {
                    transform: translate(${getRandomNumberRough()}px, ${getRandomNumberRough()}px) rotate(${getRandomNumberRough()}deg) translateZ(${getRandomNumber()}px);
                }

                85% {
                    transform: translate(${getRandomNumber()}px, ${getRandomNumber()}px) rotate(${getRandomNumber()}deg) translateZ(${getRandomNumber()}px);
                }

                100% {
                    //transform: translate(0px, 0px) rotate(0deg) translateZ(0px);
                }
            }
        `;
        const style = document.createElement('style');
        style.textContent = keyframes;
        document.head.appendChild(style);
    };
    // 获取一个随机数 用于洗牌平移范围
    const getRandomNumber = () => {
        return Math.random() * 300 - 150;
    };
    // 获取一个随机数 用于牌面动画延迟时间
    const getRandomNumberRange = (min, max) => {
        return Math.random() * (max - min) + min;
    };
    // 获取一个随机数 用于洗牌中间的不规则范围
    const getRandomNumberRough = () => {
        return Math.random() * 20 - 10;
    }

    // 应用动态生成的关键帧动画到元素
    const applyAnimationToElement = (element, animationName) => {
        element.style.animation = `${animationName} 3s ease-in-out ${getRandomNumberRange(0.01, 0.4)}s both`; // 调整动画持续时间等参数
    };

    // 请求卡牌数据
    const getCardData = () => {
        axios({
            method: 'post',
            url: httpUrl + '/api/dtc_kaluo',
            data: {
                "rec_count": 3
            }
        })
            .then(function (response) {
                setCardImg0(response.data.data[0].new_img)
                setCardImg1(response.data.data[1].new_img)
                setCardImg2(response.data.data[2].new_img)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getCardData();
        if (!animationFinished) {
            const parentElement = document.querySelectorAll('.card-item_3');
            parentElement.forEach((element, index) => {
                element.style.transform = `translateY(${index * -0.2}px) translateX(${index * -0.2}px)`;
            });
        }
        setTimeout(() => {
            if (!animationFinished) {
                cardRefs.current.forEach((ref, index) => {
                    const animationName = `step-1-${index + 1}`;
                    generateKeyframes(animationName);
                    applyAnimationToElement(ref, animationName);
                });
            }
        }, 1000);
    }, []);

    useEffect(() => {
        // 设置计时器，在默认动画结束后切换到 step-1
        const timer = setTimeout(() => {
            setAnimationFinished(true);
        }, 5000);
        // 清除计时器以避免内存泄漏
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="page-container">
            {!animationFinished && (
                <div className="main-container_3">
                    <div className="card-container_3">
                        {Array.from({ length: 78 }, (_, index) => (
                            <div
                                key={index}
                                ref={(element) => (cardRefs.current[index] = element)} // 使用 ref 属性将每个卡牌元素保存到 cardRefs 数组中
                                // 如果卡牌索引与选中的卡牌索引匹配，则应用 step-4，否则应用 step-3
                                className={`card-item_3`}
                            >
                                <div className="card_3 van-image_3">
                                    <img src="https://cdn.seeronnet.com/web-tf/dist/emotionResult/img/tarot.8795537c.png" className="van-image__img_3" alt="Tarot Card" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {animationFinished && (
                <>
                    <div className="operatingArea">
                        <div className="openFormid2">
                            {/* 使用循环生成所有的itemBox */}
                            {Array.from({ length: totalItemBoxes }).map((_, index) => (
                                <div
                                    key={index}
                                    className={`itemBox itemBoxLocation${index} ${clickedIndexes.includes(index) ? `removeAnim${clickedIndexes.indexOf(index)}` : 'itemBoxAnim'
                                        }`}
                                    style={{ animationDelay: `${index * animationDelayIncrement}s`, marginLeft: 0 }}
                                    onClick={() => handleItemClick(index)}
                                    ref={(element) => (shuffleCardRefs.current[index] = element)}
                                ></div>
                            ))}
                            {/* 根据点击次数显示不同的图像 */}
                            <img
                                className={`chooseTarotItem chooseTarotItemAnim${idnex0}`}
                                src={`data:image/jpeg;base64,${cardImg0}`}
                            />
                            <img
                                className={`chooseTarotItem chooseTarotItemAnim${idnex1}`}
                                src={`data:image/jpeg;base64,${cardImg1}`}
                            />
                            <img
                                className={`chooseTarotItem chooseTarotItemAnim${idnex2}`}
                                src={`data:image/jpeg;base64,${cardImg2}`}
                            />
                        </div>
                    </div>
                    <div className="showTarot">
                        <div className="tarotItemBox">
                            <div className="chooseTarotBox">過去</div>
                        </div>
                        <div className="tarotItemBox" >
                            <div className="chooseTarotBox">現在</div>
                        </div>
                        <div className="tarotItemBox">
                            <div className="chooseTarotBox">未來</div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default TarotFortune;
