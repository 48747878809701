import React from "react";
import CircleAnimation from "../circle_animation/CircleAnimation";
import 'animate.css';
import "../animate/slide_in_left.css";
import { ImagePreview } from 'react-vant';

const StarDiskMessage = ({ content, showCursor, length, index, showMore, isShowDetail, moreContent }) => {
    const images = [content.pic_url]
    return (
        <div className="ai_content slide-in-left">
            <div className="ai_content_message">
                <div className="message_p" >
                    <div className="star_disk" style={{ width: '100%', float: 'left', display: 'flex', justifyContent: 'center' }}>
                        <img style={{ width: "100%", height: '100%', marginBottom: '10px', cursor: 'pointer',borderRadius:'12px' }}
                            onClick={() => ImagePreview.open({images})}
                            src={content.pic_url} />
                    </div>
                    {/* <span dangerouslySetInnerHTML={{ __html: content }} />{length - 1 == index && showCursor && (<CircleAnimation />)} */}
                    {isShowDetail && !showCursor && moreContent && (
                        <span dangerouslySetInnerHTML={{ __html: moreContent }} />
                    )}
                    {showCursor && (<CircleAnimation />)}
                </div>
                {!isShowDetail && (
                    <div className="hide_message" onClick={() => showMore(index, content.md5_hash, '')}>
                        <span className="hide_name">View More Details</span>
                        <img className="hide_img"
                            src={require('../../../assets/img/hide_img.png')} />
                    </div>
                )}
            </div>
        </div>
    )
}
export default StarDiskMessage;