import React, { useEffect, useRef, useState } from "react";
import { Radio, Button } from 'react-vant';
import CircleAnimation from "../circle_animation/CircleAnimation";
import 'animate.css';
import "../animate/slide_top.css";
import "../animate/scale_in_ver_top.css";
import "../animate/slide_in_left.css";
import { getUserInfoLanguage, getStarSigns, getGenderLanguage, getTarotPopup } from "./locale.js"
import { Dropdown, Menu, DatePicker } from "antd";
import dayjs from 'dayjs';

const audioBackground = require("../../../assets/img/audio_backround.png");
const playAudioimg = require("../../../assets/img/play_audio.png");
const stopAudioimg = require("../../../assets/img/stop_audio.gif");

const AiMessage = ({ avator, content, category, radioGender, radioBloodType, chooseStar, chooseBaZi, chooseTarot, showCursor, length, index, locale, handleStarConfirm, handleBirthdayConfirm, handGenderConfirm, moreContent, cesuan_hide_key, isShowDetail, showMore, playAudio, stopAudio, playAudioIndex,showAudioLoading }) => {
    const [animationClass, setAnimationClass] = useState("");
    const [open, setOpen] = useState(false)
    const defaultValue = dayjs('1999-01-01');

    let defaultbloodType = 'A'
    let star_columns = getStarSigns(locale);
    let gender_items = getGenderLanguage(locale);
    let defaultgender = gender_items[2];
    const disabled = !(length - 1 == index || length - 2 == index)
    const handleMenuClick = (e) => {
        const clickedValue = star_columns[e.key];
        handleStarConfirm(clickedValue)
    };

    const handleMenuGenderClick = (e) => {
        const clickedValue = gender_items[e.key];
        handGenderConfirm(clickedValue)
    };


    const menuItems = star_columns.map((item, index) => ({
        key: index,
        label: item,
    }));

    const menu = (
        <Menu onClick={handleMenuClick} items={menuItems} />
    );

    const gendermenuItems = gender_items.map((item, index) => ({
        key: index,
        label: item,
    }));

    const menuGender = (
        <Menu onClick={handleMenuGenderClick} items={gendermenuItems} />
    );

    const handleButtonClick = () => {
        setOpen(true);
    };

    const handleOk = (value) => {
        //value.format('YYYY-MM-DD HH:mm:ss')
        handleBirthdayConfirm(value)
        setOpen(false); // 关闭日期选择面板
    };
    const handleOpenChange = (status) => {
        if (!status) {
            setOpen(false);
        }
    };

    useEffect(() => {
        if (length - 1 === index) {
            setAnimationClass("scale-in-ver-top");
        } else {
            setAnimationClass("");
        }
    }, [length, index]);

    if (category == '1002' || category == '2001' || category == '2003' || category == '3001') {
        const localBottomList = JSON.parse(localStorage.getItem("bottomList")) || {};
        if (localBottomList.bloodType) {
            defaultbloodType = localBottomList.bloodType
        } else {
            defaultbloodType = 'A'
        }
        if (localBottomList.gender) {
            defaultgender = localBottomList.gender
        } else {
            defaultgender = gender_items[2];
        }
    }

    // 将文本按 "\n\n" 分割成多个段落
    let paragraphs = '';
    if (moreContent && moreContent != '') {
        paragraphs = moreContent.split('\n\n');
    }
    const DivisionMessage = ({ content, index , playAudioIndex }) => {
        return (
            <div className="ai_content_message" style={{ marginTop: '30px' }}>
                <div className="message_p">
                    <span dangerouslySetInnerHTML={{ __html: content }} />
                </div>
                <div style={{
                    position: 'absolute',
                    marginLeft: '-15px', marginTop: '-35px',
                    height: '26px',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row',
                }}>
                    <div className="div_audio" style={{
                        width: '51px', height: '26px',
                        backgroundImage: `url(${audioBackground})`,
                        backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                        display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}>
                        {playAudioIndex == index && (
                            <img onClick={() => stopAudio(true)} src={stopAudioimg} style={{ width: '26px', height: '26px', padding: '5px', boxSizing: 'border-box', margin: 'auto' }}></img>
                        )}
                        {playAudioIndex != index && (
                            <img onClick={() => playAudio(content, index)} src={playAudioimg} style={{ width: '26px', height: '26px', padding: '5px', boxSizing: 'border-box', margin: 'auto' }}></img>
                        )}
                    </div>
                    {playAudioIndex == index && showAudioLoading &&(
                        <span style={{ color: 'white', marginLeft: '10px', textAlign: 'center' }}>Hold on for a few seconds.</span>
                    )}
                </div>
            </div>
        )
    };
    return (
        <div className="ai_content slide-in-left" style={{ flexDirection: isShowDetail ? 'column' : 'row' }}>
            {/* <img src={avator} /> */}
            <div className="ai_content_message">
                <div className="message_p">
                    <span dangerouslySetInnerHTML={{ __html: content }} />{length - 1 == index && showCursor && (<CircleAnimation />)}
                </div>
                {((category == '9000') && !isShowDetail && (
                    <div className="hide_message" onClick={() => showMore(index, '', cesuan_hide_key)}>
                        <span className="hide_name">View More Details</span>
                        <img className="hide_img"
                            src={require('../../../assets/img/hide_img.png')} />
                    </div>
                ))}
                {((category == '1002' && length - 1 != index) || (category == '1002' && !showCursor)) && (
                    <div className={animationClass} >
                        <div className="divider"></div>
                        <div className="div_radio" translate="no">
                            {getUserInfoLanguage(locale)[1]}
                            <Radio.Group className="radio_group" defaultValue={defaultgender} direction="horizontal" checkedColor="#69BDDC" iconSize="12px" onChange={radioGender} disabled={disabled}>
                                <Radio className="radio" name={gender_items[0]} >{gender_items[0]}</Radio>
                                <Radio className="radio" name={gender_items[1]} >{gender_items[1]}</Radio>
                                <Radio className="radio" name={gender_items[2]} >{gender_items[2]}</Radio>
                            </Radio.Group>
                        </div>
                        <div className="div_radio" translate="no">
                            {getUserInfoLanguage(locale)[2]}
                            <Radio.Group className="radio_group" defaultValue={defaultbloodType} direction="horizontal" checkedColor="#69BDDC" iconSize="12px" onChange={radioBloodType} disabled={disabled}>
                                <Radio className="radio" name="A" >A</Radio>
                                <Radio className="radio" name="B" >B</Radio>
                                <Radio className="radio" name="O" >O</Radio>
                                <Radio className="radio" name="AB" >AB</Radio>
                            </Radio.Group>
                        </div>
                        <div className="div_choose_pc" translate="no" onClick={!disabled ? handleButtonClick : undefined}>
                            {getUserInfoLanguage(locale)[0]} <span className="chooseBirthday_pc"  >  {getUserInfoLanguage(locale)[4]} </span>
                        </div>
                        <DatePicker
                            className="datePicker_pc"
                            showTime
                            open={open}
                            defaultValue={defaultValue}
                            popupStyle={{ visibility: 'visible', zIndex: 9999 }}
                            style={{ width: 0, height: 0, opacity: 0 }}
                            onOk={handleOk}
                            onOpenChange={handleOpenChange}
                        />
                        <div className="div_choose" translate="no" onClick={!disabled ? chooseStar : undefined}>
                            {getUserInfoLanguage(locale)[0]} <span className="chooseBirthday"  >  {getUserInfoLanguage(locale)[4]} </span>
                        </div>
                    </div>
                )}
                {((category == '3001' && length - 1 != index) || (category == '3001' && !showCursor)) && (
                    <div className={animationClass}>
                        <div className="divider"></div>
                        <div className="div_radio" translate="no" >
                            {getUserInfoLanguage(locale)[1]}  <Radio.Group className="radio_group" defaultValue={defaultgender} direction="horizontal" checkedColor="#69BDDC" iconSize="12px" onChange={radioGender} disabled={disabled}>
                                <Radio className="radio" name={gender_items[0]} >{gender_items[0]}</Radio>
                                <Radio className="radio" name={gender_items[1]} >{gender_items[1]}</Radio>
                                <Radio className="radio" name={gender_items[2]} >{gender_items[2]}</Radio>
                            </Radio.Group>
                        </div>

                        {/* <Dropdown
                            overlay={menuGender}
                            trigger={[!disabled ? 'click' : undefined]}
                            overlayStyle={{ zIndex: 9999 }}
                        >
                            <div className="div_choose_pc" onClick={e => e.preventDefault()}>
                                {getUserInfoLanguage(locale)[0]}
                                <span className="chooseGender_pc"> {gender_items[2]} </span>
                            </div>
                        </Dropdown>
                        <div className="div_choose" onClick={!disabled ? chooseBaZi : undefined}>
                            {getUserInfoLanguage(locale)[0]}
                            <span className="chooseGender"> {gender_items[2]} </span>
                        </div> */}

                        <div className="div_choose_pc" translate="no" onClick={!disabled ? handleButtonClick : undefined}>
                            {getUserInfoLanguage(locale)[0]} <span className="chooseBirthday_pc"  >  {getUserInfoLanguage(locale)[4]} </span>
                        </div>
                        <DatePicker
                            className="datePicker_pc"
                            showTime
                            open={open}
                            defaultValue={defaultValue}
                            popupStyle={{ visibility: 'visible', zIndex: 9999 }}
                            style={{ width: 0, height: 0, opacity: 0 }}
                            onOk={handleOk}
                            onOpenChange={handleOpenChange}
                        />
                        <div className="div_choose" translate="no" onClick={!disabled ? chooseBaZi : undefined}>
                            {getUserInfoLanguage(locale)[0]}
                            <span className="chooseBirthday"  >  {getUserInfoLanguage(locale)[4]}  </span>
                        </div>
                    </div>
                )}
                {((category == '3002' && length - 1 != index) || (category == '3002' && !showCursor)) && (
                    <div className={animationClass}>
                        <div className="divider"></div>
                        <div className="div_choose_pc" translate="no" onClick={!disabled ? handleButtonClick : undefined}>
                            {getUserInfoLanguage(locale)[0]} <span className="chooseBirthday_pc"  >  {getUserInfoLanguage(locale)[4]} </span>
                        </div>
                        <DatePicker
                            className="datePicker_pc"
                            showTime
                            open={open}
                            defaultValue={defaultValue}
                            popupStyle={{ visibility: 'visible', zIndex: 9999 }}
                            style={{ width: 0, height: 0, opacity: 0 }}
                            onOk={handleOk}
                            onOpenChange={handleOpenChange}
                        />
                        <div className="div_choose" translate="no" onClick={!disabled ? chooseBaZi : undefined}>
                            {getUserInfoLanguage(locale)[0]}
                            <span className="chooseBirthday"  >  {getUserInfoLanguage(locale)[4]}  </span>
                        </div>
                    </div>
                )}
                {((category == '2001' && length - 1 != index) || (category == '2001' && !showCursor)) && (
                    <div className={animationClass}>
                        <div className="divider"></div>
                        {/* <div className="div_radio">
                            {getUserInfoLanguage(locale)[1]}  <Radio.Group className="radio_group" defaultValue={defaultgender} direction="horizontal" checkedColor="#69BDDC" iconSize="12px" onChange={radioGender} disabled={disabled}>
                                <Radio className="radio" name={gender_items[0]} >{gender_items[0]}</Radio>
                                <Radio className="radio" name={gender_items[1]}>{gender_items[1]}</Radio>
                                <Radio className="radio" name={gender_items[2]} >{gender_items[2]}</Radio>
                            </Radio.Group>
                        </div> */}
                        {/* <Dropdown
                            overlay={menuGender}
                            trigger={[!disabled ? 'click' : undefined]}
                            overlayStyle={{ zIndex: 9999 }}
                        >
                            <div className="div_choose_pc" onClick={e => e.preventDefault()}>
                                {getUserInfoLanguage(locale)[0]}
                                <span className="chooseGender_pc"> {gender_items[2]} </span>
                            </div>
                        </Dropdown>
                        <div className="div_choose" onClick={!disabled ? chooseStar : undefined}>
                            {getUserInfoLanguage(locale)[0]}
                            <span className="chooseGender"> {gender_items[2]} </span>
                        </div> */}

                        <Dropdown
                            overlay={menu}
                            trigger={[!disabled ? 'click' : undefined]}
                            overlayStyle={{ zIndex: 9999 }}
                        >
                            <div className="div_choose_pc" translate="no" onClick={e => e.preventDefault()}>
                                {getUserInfoLanguage(locale)[0]}
                                <span className="chooseSign_pc"  > {getUserInfoLanguage(locale)[3]} </span>
                            </div>
                        </Dropdown>
                        <div className="div_choose" translate="no" onClick={!disabled ? chooseStar : undefined}>
                            {getUserInfoLanguage(locale)[0]}
                            <span className="chooseSign"  > {getUserInfoLanguage(locale)[3]} </span>
                        </div>
                    </div>
                )}
                {((category == '2003' && length - 1 != index) || (category == '2003' && !showCursor)) && (
                    <div className={animationClass}>
                        <div className="divider"></div>
                        <div className="div_radio" translate="no" >
                            {getUserInfoLanguage(locale)[1]}  <Radio.Group className="radio_group" defaultValue={defaultgender} direction="horizontal" checkedColor="#69BDDC" iconSize="12px" onChange={radioGender} disabled={disabled}>
                                <Radio className="radio" name={gender_items[0]} >{gender_items[0]}</Radio>
                                <Radio className="radio" name={gender_items[1]}>{gender_items[1]}</Radio>
                                <Radio className="radio" name={gender_items[2]} >{gender_items[2]}</Radio>
                            </Radio.Group>
                        </div>
                        {/* <Dropdown
                            overlay={menuGender}
                            trigger={[!disabled ? 'click' : undefined]}
                            overlayStyle={{ zIndex: 9999 }}
                        >
                            <div className="div_choose_pc" onClick={e => e.preventDefault()}>
                                {getUserInfoLanguage(locale)[0]}
                                <span className="chooseGender_pc"> {gender_items[2]} </span>
                            </div>
                        </Dropdown>
                        <div className="div_choose" onClick={!disabled ? chooseStar : undefined}>
                            {getUserInfoLanguage(locale)[0]}
                            <span className="chooseGender"> {gender_items[2]} </span>
                        </div> */}

                    </div>
                )}
                {((category == '3003' && length - 1 != index) || (category == '3003' && !showCursor)) && (
                    <div className={animationClass}>
                        <div className="divider"></div>
                        <div className="div_choose" translate="no" onClick={length - 1 == index ? chooseTarot : undefined}>
                            <Button className="chooseTarot" color='#B89054'> {getTarotPopup(locale)[1]} </Button>
                        </div>
                        <div className="div_choose_pc" translate="no" onClick={length - 1 == index ? chooseTarot : undefined}>
                            <Button className="chooseTarot" color='#B89054'> {getTarotPopup(locale)[1]} </Button>
                        </div>
                    </div>
                )}
            </div>
            {((length - 1 != index) || (length - 1 == index && !showCursor)) && (
                <div style={{
                    position: 'absolute',
                    top: '-20px',
                    height: '26px',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row',
                }}>
                    <div className="div_audio" style={{
                        width: '51px', height: '26px',
                        backgroundImage: `url(${audioBackground})`,
                        backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                        display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}>
                        {playAudioIndex == index && (
                            <img onClick={() => stopAudio(true)} src={stopAudioimg} style={{ width: '26px', height: '26px', padding: '5px', boxSizing: 'border-box', margin: 'auto' }}></img>
                        )}
                        {playAudioIndex != index && (
                            <img onClick={() => playAudio(content, index)} src={playAudioimg} style={{ width: '26px', height: '26px', padding: '5px', boxSizing: 'border-box', margin: 'auto' }}></img>
                        )}
                    </div>
                    {playAudioIndex == index && showAudioLoading && (
                        <span style={{ color: 'white', marginLeft: '10px', textAlign: 'center' }}>Hold on for a few seconds.</span>
                    )}
                </div>
            )}
            {category == '9000' && moreContent != '' && isShowDetail && (
                paragraphs.map((paragraph, index) => (
                    <DivisionMessage key={index} content={paragraph} index={99 + index} playAudioIndex={playAudioIndex} />
                ))
            )}
        </div>
    )
}
export default AiMessage;