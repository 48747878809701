import React, { useState, useEffect, useRef } from 'react';
import CryptoJS from 'crypto-js';
import WebSocketClient from "../../../utils/websocket";
import Decoder from './Decoder';
const AudioRecorder = ({ isRecording, onDataAvailable, onStop, language_type, onVolumeValue, vadDelay }) => {

    const appid = "ga41a9d9"
    const StatusFirstFrame = 0
    const StatusContinueFrame = 1
    const StatusLastFrame = 2
    var status = StatusFirstFrame;

    const streamRef = useRef(null);
    const audioContextRef = useRef(null);
    const audioProcessorRef = useRef(null);
    const wsRef = useRef(null);
    //const decoderRef = useRef(new Decoder()); // Decoder 实例的引用
    let language = "en_us"
    let transcript = ""

    useEffect(() => {
        if (!vadDelay) {
            vadDelay = 10000;
        }
        if (isRecording) {
            status = StatusFirstFrame;
            switch (language_type) {
                case 'zh-CN':
                    language = "zh_cn";
                    break;
                case 'ja':
                    language = "ja_jp";
                    break;
                case 'ko':
                    language = "ko_kr";
                    break;
                case 'es':
                    language = "es_es";
                    break;
            }
            getAuthUrl();
        } else {
            if (wsRef.current != null) {
                status = StatusLastFrame
                sendPCMData();
            }
        }
    }, [isRecording]);

    const startRecorder = async () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            console.error('getUserMedia is not supported in this browser.');
            return;
        }
        const isGoogleBrowser = /Chrome/.test(navigator.userAgent) || /Edg/.test(navigator.userAgent);
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            streamRef.current = stream;

            // 根据浏览器类型设置 AudioContext
            let audioContext;
            if (isGoogleBrowser) {
                // Google 浏览器（Chrome、Edge），直接设置采样率
                audioContext = new (window.AudioContext || window.webkitAudioContext)({ sampleRate: 16000 });
            } else {
                // 其他浏览器，使用默认采样率
                audioContext = new (window.AudioContext || window.webkitAudioContext)();
            }
            audioContextRef.current = audioContext;

            const source = audioContext.createMediaStreamSource(stream);
            const processor = audioContext.createScriptProcessor(1024, 1, 1);
            processor.onaudioprocess = (e) => {
                const inputBuffer = e.inputBuffer;
                const outputBuffer = new Float32Array(inputBuffer.length);
                let sum = 0.0;
                for (let i = 0; i < inputBuffer.length; i++) {
                    outputBuffer[i] = inputBuffer.getChannelData(0)[i];
                    sum += outputBuffer[i] * outputBuffer[i];
                }
                const rms = Math.sqrt(sum / inputBuffer.length);
                const volumeValue = Math.min(1, rms) * 100; // 将音量转换为0-100范围
                onVolumeValue(volumeValue);

                // 如果不是 Google 浏览器，进行重新采样
                if (!isGoogleBrowser) {
                    // 进行重新采样
                    const resampledBuffer = resampleAudio(outputBuffer, audioContext.sampleRate, 16000);
                    const int16Array = float32ToInt16(resampledBuffer);
                    const base64String = arrayBufferToBase64(int16Array.buffer);

                    if (wsRef.current) {
                        sendPCMData(base64String);
                    }
                } else {
                    const int16Array = float32ToInt16(outputBuffer);
                    const base64String = arrayBufferToBase64(int16Array.buffer);

                    if (wsRef.current) {
                        sendPCMData(base64String);
                    }
                }
            };

            source.connect(processor);
            processor.connect(audioContext.destination);
            audioProcessorRef.current = processor;
        } catch (error) {
            console.error('Error starting recorder:', error);
        }
    };

    // 简单的重新采样函数 (线性插值示例)
    const resampleAudio = (inputBuffer, originalSampleRate, targetSampleRate) => {
        const ratio = originalSampleRate / targetSampleRate;
        const newLength = Math.round(inputBuffer.length / ratio);
        const outputBuffer = new Float32Array(newLength);

        for (let i = 0; i < newLength; i++) {
            const index = i * ratio;
            const nextIndex = Math.min(inputBuffer.length - 1, Math.ceil(index));
            const weight = index - Math.floor(index);
            outputBuffer[i] = inputBuffer[Math.floor(index)] * (1 - weight) + inputBuffer[nextIndex] * weight;
        }
        return outputBuffer;
    };


    const float32ToInt16 = (float32Array) => {
        const int16Array = new Int16Array(float32Array.length);
        for (let i = 0; i < float32Array.length; i++) {
            int16Array[i] = Math.min(1, float32Array[i]) * 0x7FFF;
        }
        return int16Array;
    };

    const arrayBufferToBase64 = (buffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };


    const stopRecorder = (data) => {
        setTimeout(() => {
            if (audioProcessorRef.current != null) {
                audioProcessorRef.current.disconnect();
                audioProcessorRef.current = null;
            }
            if (streamRef.current != null) {
                streamRef.current.getTracks().forEach(track => track.stop());
                streamRef.current = null;
            }
            if (audioContextRef.current != null) {
                audioContextRef.current.close().catch(error => console.error('Error closing audio context:', error));
                audioContextRef.current = null;
            }
            if (wsRef.current) {
                // 确保 WebSocket 关闭完成
                wsRef.current.onclose = () => {
                    wsRef.current = null;
                    if (data) {
                        onStop(transcript);
                        transcript = '';
                    }
                };
                wsRef.current.close();
            }
            if (data) {
                onStop(transcript);
                transcript = '';
            }
        }, 500);
    };
    const getAuthUrl = async () => {
        try {
            const hostUrl = 'https://iat-api-sg.xf-yun.com/v2/iat'; // Replace with your actual host URL
            const apiKey = '40682ee31835c0cd68932e25903a3d36'; // Replace with your actual API key
            const apiSecret = '8b479c89b34f39b3abecfed06d2cbfa5'; // Replace with your actual API secret

            const url = new URL(hostUrl);
            const date = new Date().toUTCString();
            const builder = `host: ${url.host}\ndate: ${date}\nGET ${url.pathname} HTTP/1.1`;

            const hmacDigest = CryptoJS.HmacSHA256(builder, apiSecret);
            const sha = CryptoJS.enc.Base64.stringify(hmacDigest);

            const authorization = `api_key="${apiKey}", algorithm="hmac-sha256", headers="host date request-line", signature="${sha}"`;

            const httpUrl = new URL(hostUrl.replace("https", "wss"));
            httpUrl.searchParams.append('authorization', btoa(authorization));
            httpUrl.searchParams.append('date', date);
            httpUrl.searchParams.append('host', url.host);

            wsRef.current = new WebSocketClient(httpUrl.toString());
            wsRef.current.onOpen = () => {
                console.log("WS ASR connected");
                transcript = "";
                startRecorder();
            };
            wsRef.current.onMessage = (message) => {
                handleWebSocketMessage(message);
            };
            wsRef.current.onClose = () => {
                console.log("WS ASR Close");
                stopRecorder(true);
            };
            wsRef.current.onError = (error) => {
                console.log("WS ASR Error", error);
                stopRecorder(false);
            };
            wsRef.current.connect();
        } catch (error) {
            console.error('Error generating auth URL:', error);
        }
    };
    // 处理 WebSocket 收到的消息
    const handleWebSocketMessage = (message) => {
        try {
            const resp = JSON.parse(message);
            // 检查返回的 code，处理异常情况
            if (resp.code !== 0) {
                console.log(`code => ${resp.code}, error => ${resp.message}, sid = ${resp.sid}`);
                console.log('Please follow the error message tips to handle');
                return;
            }
            // 如果存在数据并且有结果，则处理识别结果
            if (resp.data && resp.data.result) {
                const wordsArray = resp.data.result.ws.map(ws => ws.cw[0].w);
                const decodedText = wordsArray.join('');
                transcript = transcript + decodedText;
                onDataAvailable(transcript)
            }
            // 如果数据状态为 2，表示会话结束，进行相应处理
            if (resp.data && resp.data.status === 2) {
                stopRecorder(false);
            }
        } catch (error) {
            console.error('Error parsing WebSocket message:', error);
        }
    };
    const sendPCMData = (audioBuffer) => {
        switch (status) {
            case StatusFirstFrame:
                const frame = {
                    common: { app_id: appid },
                    business: { language: language, domain: 'iat', accent: 'mandarin', vad_eos: vadDelay },
                    data: {
                        status: StatusFirstFrame,
                        format: 'audio/L16;rate=16000',
                        encoding: 'raw',
                        audio: audioBuffer,
                    },
                };
                wsRef.current.send(JSON.stringify(frame));
                status = StatusContinueFrame
                break;
            case StatusContinueFrame:
                const frame1 = {
                    data: {
                        status: StatusContinueFrame,
                        format: 'audio/L16;rate=16000',
                        encoding: 'raw',
                        audio: audioBuffer,
                    },
                };
                wsRef.current.send(JSON.stringify(frame1));
                break;
            case StatusLastFrame:
                const frame2 = {
                    data: {
                        status: StatusLastFrame,
                        audio: '',
                        format: 'audio/L16;rate=16000',
                        encoding: 'raw',
                    },
                };
                wsRef.current.send(JSON.stringify(frame2));
                break;
            default:
                break;
        }
    };
    return null;
};

export default AudioRecorder;
