import React from "react";
import CircleAnimation from "../circle_animation/CircleAnimation";

const HelloMessage = ({ avatar, messagelist, name, background, inputTips, tipsList }) => {
    return (
        <div className="hello_message">
            <span className="hello_message_name">{name}</span>
            <img className="hello_message_avatar" alt="avatar"
                src={avatar} 
            />
            <div className="hello_message_list">
                {messagelist.map((messageData, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className="hello_message_content" dangerouslySetInnerHTML={{ __html: messageData.content }} />
                        <br />
                    </div>
                ))}
            </div>
            {tipsList && (
                <div className="hello_tips" style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    {tipsList.map((data, index) => (
                        <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className="hello_tips_content" onClick={() => inputTips(data,index)}><strong>?</strong>&nbsp;&nbsp;{data}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}
export default HelloMessage;