import React from "react";

const HeadTitle = ({avator,botName,roleClick}) => {
    return (
        <div className="head_title" onClick={roleClick}>
            <img className="head_title_img" src={avator}/>
            <span className="head_title_name">{botName}</span>
        </div>
    )
}
export default HeadTitle;