import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'normalize.css/normalize.css';
import 'lib-flexible';
import ReactGA4 from 'react-ga4';


const MEASUREMENT_ID = 'G-S9H8ENM4WW';

ReactGA4.initialize(MEASUREMENT_ID, {
  debug: false,
});

const isWeChatBrowser = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.includes('micromessenger');
};

if (isWeChatBrowser()) {
  // 如果在微信浏览器中打开，可以在此处执行相关处理，例如显示提示信息或引导用户在浏览器中打开
  console.log("当前在微信浏览器中打开页面");
  document.body.innerHTML = `<p>请点击右上角的菜单，然后选择“在浏览器中打开”以获得最佳体验。</p>`;
} else {
  //在其他浏览器中正常渲染应用
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    // <React.StrictMode>
    <App />
    // </React.StrictMode>
  );
}

reportWebVitals();
