import React, { useRef, useState, useEffect } from 'react';
import { fetchData } from '../../utils/fetchDataService'; // 导入封装的 fetchData 函数
import OAuth from 'oauth-1.0a';
import crypto, { mode } from 'crypto-js';
import { wcUrl } from '../../utils/apiService';
import './test.css'
import ColorChangeButton from './subview/ColorChangeButton';
import { Rate } from 'react-vant';
import { FilledIcon, EmptyIcon, HalfIcon } from './subview/RateIcon.js';


// import * as PIXI from 'pixi.js';
// import { Live2DModel } from 'pixi-live2d-display';

import rateYes from '../../assets/img/rate_yes.png';
import rateNo from '../../assets/img/rate_no.png';
import Rating from './subview/Rating.js';
import Asr from './subview/asr/Asr.js';

// const rateYes = require(`../../assets/img/rate_yes.png`);
//     const rateNo = require(`../../assets/img/rate_no.png`);

// 将 PIXI 暴露到 window 上，这样插件就可以通过 window.PIXI.Ticker 来自动更新模型
//window.PIXI = PIXI;

const Chat = () => {

    // 创建一个数组，包含不同的文本内容
    const texts = [
        '看见我，你就等着发财吧，没开玩笑，就是牛逼！！！',
        '今天的你太棒了！',
        '每一天都是新的开始！',
        '相信自己的力量！',
        '你一定会成功！'
    ];

    // 使用 useState 管理当前显示的文本索引
    const [currentTextIndex, setCurrentTextIndex] = useState(0);

    // 状态来控制 <div> 的显示与隐藏
    const [showDiv, setShowDiv] = useState(false);
    const [closeAnimationTriggered, setCloseAnimationTriggered] = useState(false);



    // 使用 useRef 创建对 canvas 的引用
    const canvasRef = useRef(null);
    const canvasDivRef = useRef(null);
    // useEffect(() => {
    //     // 创建 PIXI 应用程序
    //     const app = new PIXI.Application({
    //         view: canvasRef.current,
    //         autoStart: true,
    //         resizeTo: window,
    //         backgroundAlpha: 0,
    //     });
    //     // 异步加载 Live2D 模型
    //     const loadModel = async () => {
    //         try {
    //             // 加载 Live2D 模型
    //             const model = await PIXI.live2d.Live2DModel.from('../../../model/runtime/mao_pro.model3.json');

    //             // 将模型添加到 PixiJS 应用程序舞台
    //             app.stage.addChild(model);

    //             model.scale.set(0.1);
    //             model.on('pointerup', (event) => {
    //                 console.log('模型被点击了！');
    //                 // 在这里可以添加自定义点击行为
    //                 //setShowDiv(true)
    //                 //setCloseAnimationTriggered(false);

    //             });

    //         } catch (error) {
    //             console.error('加载模型出错：', error);
    //         }
    //     };
    //     // 加载模型
    //     //loadModel();

    //     const fetchFollowers = async () => {
    //         try {
    //             // // 假设你有 WooCommerce REST API 的消费者密钥和消费者秘钥
    //             // const consumer_key = 'ck_99ef2f06bffa481d2c8a3d93ad016c7cd51907c9';
    //             // const consumer_secret = 'cs_937eecefb673282b59572e07755c2564b62be7bc';

    //             // // 组合 consumer_key 和 consumer_secret
    //             // const authString = `${consumer_key}:${consumer_secret}`;

    //             // // 使用 btoa 对组合字符串进行 Base64 编码
    //             // const authBase64 = btoa(authString);

    //             // // 生成 Authorization 头
    //             // const headers = {
    //             //     'Authorization': `Basic ${authBase64}`,
    //             // };


    //             const consumerKey = 'ck_99ef2f06bffa481d2c8a3d93ad016c7cd51907c9';
    //             const consumerSecret = 'cs_937eecefb673282b59572e07755c2564b62be7bc';

    //             const oauth = new OAuth({
    //                 consumer: {
    //                     key: consumerKey,
    //                     secret: consumerSecret,
    //                 },
    //                 signature_method: 'HMAC-SHA1', // 或 'HMAC-SHA256'
    //                 hash_function: (baseString, key) => {
    //                     return crypto.HmacSHA1(baseString, key).toString(crypto.enc.Base64);
    //                 },
    //             });
    //             const requestData = {
    //                 url: wcUrl + '/wp-json/wc/v3/orders',
    //                 method: 'GET',
    //                 data: {}, // 如果请求需要带有请求体数据
    //             };
    //             const authHeader = oauth.toHeader(oauth.authorize(requestData));
    //             const response = await fetchData('GET', '/wp-json/wc/v3/orders', {}, authHeader);
    //             console.info(response); // 打印响应数据
    //         } catch (err) {
    //             console.error(err); // 处理错误
    //         }
    //     };
    //     fetchFollowers(); // 在组件加载时调用异步函数

    //     // 定义一个定时器
    //     const intervalId = setInterval(() => {
    //         // 每两秒切换到下一个文本
    //         setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    //     }, 2000);

    //     // 在组件卸载时清除定时器
    //     return () => {
    //         clearInterval(intervalId);
    //     };

    // }, []);

    const createKeyframes = (translateX, translateY) => {
        const styleSheet = document.styleSheets[0];
        const keyframes =
            `@keyframes shrinkAndMove {
                0% {
                    border-radius: 0%;
                    clip-path: circle(100% at 50% 50%);
                    transform: translate(0, 0);
                }
                50% {
                    border-radius: 50%;
                    clip-path: circle(50px at 50% 50%);
                    transform: translate(0, 0);
                }
                100% {
                    border-radius: 50%;
                    clip-path: circle(50px at 50% 50%);
                    transform: translate(${translateX}, ${translateY});
                }
            }`;

        styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
    };

    const handleClick = () => {
        const canvasDiv = canvasDivRef.current;
        if (canvasDiv) {
            const rect = canvasDiv.getBoundingClientRect();
            const canvasWidth = rect.width;
            const canvasHeight = rect.height;

            const translateX = `calc(-50vw + ${canvasWidth / 2}px + 10px)`;
            const translateY = `calc(-50vh + ${canvasHeight / 2}px)`;

            createKeyframes(translateX, translateY);
            canvasDiv.classList.add('shrink');
            canvasDiv.addEventListener('animationend', handleAnimationEnd);
        }
    };
    const handleAnimationEnd = () => {
        setShowDiv(true);
    };

    const rating = 4.5

    const fullIconsCount = Math.floor(rating);
    const hasHalfIcon = rating % 1 !== 0;

    const icons = [];

    for (let i = 0; i < fullIconsCount; i++) {
        icons.push(<img key={i} src={FilledIcon} style={{ width: '20px', height: '20px' }} />);
    }

    if (hasHalfIcon) {
        icons.push(<img key="half" src={HalfIcon} style={{ width: '10px', height: '20px' }} />);
    }

    const emptyIconsCount = 5 - icons.length;

    for (let i = 0; i < emptyIconsCount; i++) {
        icons.push(<img key={`empty-${i}`} src={EmptyIcon} style={{ width: '20px', height: '20px' }} />);
    }

    return (
        <div onClick={handleClick} style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {/* <div ref={canvasDivRef} className='canvas' style={{ width: 'auto', maxWidth: '300px', position: 'fixed', bottom: '0', right: '10px', background: 'black', overflow: 'hidden' }}>
                <div style={{ width: 'auto', maxWidth: '120px', borderRadius: '10px 10px 0 10px', backgroundColor: 'rgba(255, 204, 0, 0.5)', padding: '10px', boxShadow: '0 0 10px rgba(0,0,0,0.2)', textAlign: 'center', margin: '0 auto -15px auto', display: 'none' }}>
                    <p>{texts[currentTextIndex]}</p>
                </div>
                <canvas ref={canvasRef} style={{ height: '400px' }} ></canvas>
            </div>

            {showDiv && (<div className={`ripple-animation show`}>
                <button onClick={() => setCloseAnimationTriggered(true)}>关闭</button>
                <iframe className="suishi_reactApp" src="http://192.168.3.72:3000/" frameborder="0"></iframe>
            </div>
            )} */}
            {/* <ColorChangeButton/> */}
            {/* <div className='love'>
                
            </div> */}
            {/* <div className='rating_div' style={{ width: '100%', height: '20px',position:'relative' }}>
                <div>
                    <img src={EmptyIcon} style={{ width: '20px', height: '20px' }}></img>
                    <img src={EmptyIcon} style={{ width: '20px', height: '20px' }}></img>
                    <img src={EmptyIcon} style={{ width: '20px', height: '20px' }}></img>
                    <img src={EmptyIcon} style={{ width: '20px', height: '20px' }}></img>
                    <img src={EmptyIcon} style={{ width: '20px', height: '20px' }}></img>
                </div>
                <div style={{ position:'absolute',top:0,left:0 }}>
                    <img src={FilledIcon} style={{ width: '20px', height: '20px' }}></img>
                    <img src={FilledIcon} style={{ width: '20px', height: '20px' }}></img>
                    <img src={HalfIcon} style={{ width: '10px', height: '20px' }}></img>
                </div>
            </div> */}
            {/* <div className='rating_div' style={{ position: 'relative', width: '100%', height: '20px' }}>
                <div style={{ width: '100%', height: '100%' }}>
                    {Array(5).fill().map((_, i) => (
                        <img key={`empty-${i}`} src={EmptyIcon} style={{ width: '20px', height: '20px' }} />
                    ))}
                </div>
                <div style={{ position: 'absolute', top: 0, left: 0, display: 'flex' }}>
                    {icons}
                </div>
            </div> */}

            {/* <Rating rating={1.4} width={20} /> */}
            
            <Asr/>

        </div>

    );
};

export default Chat;
