// 创建一个工具类文件 dateUtils.js
class ageUtils {
    /**
     * 将日期字符串转换为年龄
     */
   static calculateAge(birthDateString) {
        // 将日期字符串转换为 Date 对象
        const birthDate = new Date(birthDateString);
        
        // 获取当前日期
        const currentDate = new Date();
        
        // 计算年龄
        let age = currentDate.getFullYear() - birthDate.getFullYear();
        
        // 检查生日是否已经过去
        const birthMonthDay = new Date(
            currentDate.getFullYear(),
            birthDate.getMonth(),
            birthDate.getDate()
        );
        
        if (currentDate < birthMonthDay) {
            // 如果今年的生日还没过，减去一岁
            age -= 1;
        }
        
        return age;
    }
}

// 导出 DateUtils 类
export default ageUtils;
