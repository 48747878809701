import React, { Component } from 'react';
const changBtn = require("../../../assets/img/keybord.png");
class ColorChangeButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: 'white',
            isPressing: false,
        };
        this.longPressTimeout = null;
        this.buttonRef = React.createRef();

        this.animationRefs = Array.from({ length: 8 }, () => React.createRef()); // 创建动画元素的引用数组
    }

    componentDidMount() {
        document.addEventListener('mouseup', this.handleMouseUp);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.volume !== this.props.volume) {
            this.updateAnimationStyles(this.props.volume);
        }
        if (prevProps.vadEnd !== this.props.vadEnd && this.props.vadEnd){
            this.setState({ backgroundColor: 'white', isPressing: false });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handleMouseUp);
        clearTimeout(this.longPressTimeout);
        clearInterval(this.volumeTimer); // 清除定时器
    }

    handleMouseDown = () => {
        this.setState({ isPressing: true })
        this.longPressTimeout = setTimeout(() => {
            if (this.state.isPressing) {
                this.setState({ backgroundColor: 'blue' });
                this.props.startRecording();
            }
        }, 500); // 长按时间设为500毫秒
    };

    handleMouseUp = () => {
        clearTimeout(this.longPressTimeout);
        const { backgroundColor } = this.state;
        if (backgroundColor === 'blue') {
            // 调用父组件的 stopRecording 方法
            this.props.stopRecording();
        } else if (backgroundColor === 'red') {
            // 如果需要，可以在这里执行取消操作
            this.props.cancelRecording();
        }
        this.setState({ backgroundColor: 'white', isPressing: false });
    };

    handleMouseLeave = () => {
        clearTimeout(this.longPressTimeout);
        if (this.state.isPressing) {
            this.setState({ backgroundColor: 'red', isPressing: true });
        }
    };
    handleMouseEnter = () => {
        if (this.state.isPressing) {
            this.setState({ backgroundColor: 'blue', isPressing: true });
        }
    }
    handleTouchEnd = (event) => {
        clearTimeout(this.longPressTimeout);
        const { backgroundColor } = this.state;
        if (backgroundColor === 'blue') {
            // 调用父组件的 stopRecording 方法
            this.props.stopRecording();
        } else if (backgroundColor === 'red') {
            // 如果需要，可以在这里执行取消操作
            this.props.cancelRecording();
        }
        if (this.state.isPressing) {
            this.setState({ backgroundColor: 'white', isPressing: false });
        }
    };
    handleTouchMove = (event) => {
        // 判断手指是否移出按钮区域
        const button = this.buttonRef.current;
        if (button) {
            const rect = button.getBoundingClientRect();
            const isInButton =
                event.touches[0].clientX >= rect.left &&
                event.touches[0].clientX <= rect.right &&
                event.touches[0].clientY >= rect.top &&
                event.touches[0].clientY <= rect.bottom;
            if (!isInButton) {
                this.setState({ backgroundColor: 'red', isPressing: true });
            } else {
                this.setState({ backgroundColor: 'blue', isPressing: true });
            }
        }
    };

    updateAnimationStyles = (volume) => {
        const heightCoefficient = 0.5; // 调整系数
        let maxHeight = 0; // 最大高度
        let minHeight = 0; // 最小高度
        // 更新每个动画元素的样式
        this.animationRefs.forEach((ref, index) => {
            if (ref.current) {
                let baseHeight;
                if ([0, 3, 7].includes(index)) {
                    maxHeight = 35; // 最大高度
                    minHeight = 10; // 最小高度
                    baseHeight = volume * heightCoefficient; // 第1、4、8个元素
                } else if ([1, 4, 6].includes(index)) {
                    maxHeight = 50; // 最大高度
                    minHeight = 10; // 最小高度
                    baseHeight = (volume + 20) * heightCoefficient; // 第2、5、7个元素
                } else if ([2, 5].includes(index)) {
                    maxHeight = 50; // 最大高度
                    minHeight = 10; // 最小高度
                    baseHeight = (volume + 40) * heightCoefficient; // 第3、6个元素
                }
                // 添加随机扰动
                const transitionDuration = `${(Math.random() * 0.2 + 0.1).toFixed(2)}s`; // 生成随机过渡时间
                const randomFactor = Math.random() * 10 - 5; // -5到5之间的随机数
                const newHeight = `${Math.min(maxHeight, Math.max(minHeight, baseHeight + randomFactor))}px`; // 限制高度范围
                ref.current.style.transition = `height ${transitionDuration} ease`; // 设置过渡样式
                ref.current.style.height = newHeight;
            }
        });
    };
    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    {this.state.isPressing && (
                        <p style={{ fontSize: '12px',color:'#FFFFFF' }}>Release send, slide up to cancel</p>
                    )}
                    <button
                        ref={this.buttonRef}
                        className='voice-change-button'
                        style={{
                            width: '90%',
                            backgroundColor: this.state.backgroundColor,
                            color: 'black',
                            height: '50px',
                            border: 'none',
                            borderRadius: '10px',
                            marginBottom: '12px',
                            marginTop: '12px',
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                        }}
                        onMouseDown={this.handleMouseDown}
                        onMouseUp={this.handleMouseUp}
                        onMouseEnter={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                        onTouchStart={this.handleMouseDown}
                        onTouchMove={this.handleTouchMove}
                        onTouchEnd={this.handleTouchEnd}
                    >
                        {this.state.isPressing && (
                            <div className='animation'
                                style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {this.animationRefs.map((ref, index) => (
                                    <span
                                        key={index}
                                        ref={this.animationRefs[index]}
                                        //className={`animation${index + 1}`}
                                        style={{
                                            width: '10px',
                                            height: '10px',
                                            backgroundColor: 'white',
                                            borderRadius: '50px',
                                            marginLeft: '10px',
                                            // transition: 'height 0.2s ease', // 添加平滑过渡效果
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                        <p style={{ fontSize: '14px' }}>Hold to talk</p>
                    </button>
                    {!this.state.isPressing && (
                        <img
                            className="change_button"
                            src={changBtn}
                            style={{ width: '40px', height: '40px', position: 'absolute', right: '35px', bottom: '17px' }}
                            onClick={this.props.hideVoiceView}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default ColorChangeButton;
