// UserMessage.js
import React from 'react';
import "../animate/slide_top.css"
import "../animate/slide_in_right.css";

const UserMessageTarot = ({ content }) => {
    const showTarotAnim = () => {
        //document.querySelector('.tarotimg').style.transform= 'translateX(0px)';
        //document.querySelector('.tarotimg2').style.transform= 'translateX(0px)';
        // document.querySelector('.tarotimg1').style.marginLeft= '10px';
        // document.querySelector('.tarotimg1').style.marginRight= '10px';
        //document.querySelector('.tarot_title').style.display= 'flex';
    }
    return (
        <div className="tarot_content slide-in-right">
            <div className='tarot' onClick={showTarotAnim}>
                <div style={{ display: 'flex', flexDirection: 'row' }} >
                    <img className='tarotimg' src={`data:image/jpeg;base64,${JSON.parse(content).taluodata[0].new_img}`}>
                    </img>
                    <img className='tarotimg1' src={`data:image/jpeg;base64,${JSON.parse(content).taluodata[1].new_img}`}>
                    </img>
                    <img className='tarotimg2' src={`data:image/jpeg;base64,${JSON.parse(content).taluodata[2].new_img}`}>
                    </img>
                    {/* <div className='tarot_text'>
                        <span className='tarot_name'>
                            {JSON.parse(content).taluodata[0].name}
                        </span>
                        <span className='tarot_face_text'>
                            {JSON.parse(content).taluodata[0].face_text}
                        </span>
                        <span className='tarot_desc'>
                            {JSON.parse(content).taluodata[0].wen_keyword}
                        </span>
                    </div> */}
                </div>
                <div className='tarot_title'>
                    <div className='tarot_title_content'>
                        <span className='tarot_title_text'>{JSON.parse(content).taluodata[0].name}</span>
                        <span className='tarot_title_loction'>{JSON.parse(content).taluodata[0].face_text}</span>
                    </div>
                    <div className='tarot_title_content' style={{marginLeft: '10px',marginRight: '10px'}}>
                        <span className='tarot_title_text'>{JSON.parse(content).taluodata[1].name}</span>
                        <span className='tarot_title_loction'>{JSON.parse(content).taluodata[1].face_text}</span>
                    </div>
                    <div className='tarot_title_content'>
                        <span className='tarot_title_text'>{JSON.parse(content).taluodata[2].name}</span>
                        <span className='tarot_title_loction'>{JSON.parse(content).taluodata[2].face_text}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserMessageTarot;
