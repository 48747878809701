// src/App.js
import React from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import Chat from './pages/chat/index';
import BaZi from './pages/chat/bazi/index';
import Tarot from './pages/chat/tarot_chat/index';
import Test from './pages/chat/test';
import TaLuo from './pages/tarot/index';
import TaLuo3 from './pages/tarot_3/index';
import TaLuoOne from './pages/tarot/tarot_one';
import TaLuoTime from './pages/tarot_3/tarot_time';
import TaLuoAndroid from './pages/tarot_3/tarot_time_android';
import Home from './pages/home/index';
import Futurecuex from './pages/futurecuex/futurecuex';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Chat />} />
        <Route path='/bazi' element={<BaZi />} />
        <Route path='/tarot' element={<Tarot />} />
        <Route path='/test' element={<Test />} />
        <Route path='/taluo' element={<TaLuo />} />
        <Route path='/taluo3' element={<TaLuo3 />} />
        <Route path='/tarot_one' element={<TaLuoOne />} />
        <Route path='/tarot_time' element={<TaLuoTime />} />
        <Route path='/tarot_android' element={<TaLuoAndroid />} />
        <Route path='/home' element={<Home />} />
        <Route path='/futurecuex' element={<Futurecuex />} />
      </Routes>
    </Router>
  );
}

export default App;
