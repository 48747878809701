// UserMessage.js
import React from 'react';
import "../animate/slide_top.css"
import "../animate/slide_in_right.css";

const UserMessage = ({ content }) => {
    return (
        <div className="user_content slide-in-right">
            <div className='user_content_text'>
                <span translate='no'>{content}</span>
            </div>
        </div>
    );
}

export default UserMessage;
