import React, { useRef, useState, useEffect } from 'react';
const futurecuex = () => {


    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',background:'linear-gradient(-55deg, #040D11 0%, #09212A 47%, #040D11 100%)' }}>
            <span style={{ color:'white', fontSize: '30px'}}>Futurecuex.com</span>
        </div>
    );
};

export default futurecuex;
